import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Tooltip , IconButton } from '@mui/material';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { inFileList } from '../util';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as Sentry from '@sentry/react';

const LocalSperaterModule = inject('snackbarStore')(observer(({ snackbarStore, open, dirHandle, localPictures, pictures, onClose }) => {

    const { t } = useTranslation();
    const [selectedLocalFiles, setSelectedLocalFiles] = useState([])
    const onWriteToNewDir = useCallback(async () => {
        if( selectedLocalFiles.length === 0){
            return
        }
        try{
            const newDirHandle = await dirHandle.current.getDirectoryHandle( t('Selected'), { create: true });
            for (const fileName of selectedLocalFiles) {
                const fileHandle = await dirHandle.current.getFileHandle(fileName);
                const newFileHandle = await newDirHandle.getFileHandle(fileName, { create: true });
                const writable = await newFileHandle.createWritable();
                const readable = await fileHandle.getFile();
                await writable.write(await readable.arrayBuffer());
                writable.close();
            }
            snackbarStore.showSnackbar(t(`Write to new directory successfully`), "success")
        }catch(error){
            snackbarStore.showSnackbar(t(`Write to new directory failed`), "warning")
        }
       

    }, [selectedLocalFiles]);

    const toggleSelect = (fileName) => () => {
        if (selectedLocalFiles.includes(fileName)) {
            setSelectedLocalFiles(selectedLocalFiles.filter(item => item !== fileName))
        } else {
            setSelectedLocalFiles([...selectedLocalFiles, fileName])
        }
    }

    useEffect(() => {
        if (open) {
            let selectedPictures = []
            localPictures.forEach(item => {
                if (inFileList(pictures, item)) {
                    selectedPictures.push(item)
                }
            })
            setSelectedLocalFiles(selectedPictures)
        }
    }, [pictures, open, localPictures]);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t("Archive")}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box>
                        <Typography variant="subtitle2" >{t("Local files")}</Typography>
                        <Box sx={{ width: 400, height: 300, p: 1, overflowY: "scroll" }}>
                            {localPictures.map((item, index) => {
                                return <Box
                                    onClick={toggleSelect(item)}
                                    key={index} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 25, cursor: "pointer" }}>
                                    <Typography variant="body2">{item}</Typography>
                                    {selectedLocalFiles.find(el => el === item) ? <CheckIcon color='primary' /> : null}
                                </Box>
                            })}
                        </Box>
                    </Box>

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("Close")}
                </Button>
                <Button disabled={selectedLocalFiles.length==0} variant='contained' onClick={onWriteToNewDir} color="primary" >
                    {t("Write to a new directory")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}))

const LocalSperater = inject('snackbarStore')(observer(({ snackbarStore, pictures }) => {

    const { t } = useTranslation();
    const dirHandle = useRef(null)
    const [open, setOpen] = useState(false);
    const [localPictures, setLocalPictures] = useState([])
    const [showHelp, setShowHelp] = useState(false);

    const onClose = () => {
        setOpen(false)
    }

    const openLocalDirectory = async (event) => {
        try {
            // 用户选择原始文件夹
            dirHandle.current = await window.showDirectoryPicker();
            let fileList = []
            // 获取文件夹中的条目
            for await (const entry of dirHandle.current.values()) {
                if (entry.kind === 'file') {
                    fileList.push(entry.name)
                }
            }
            Sentry.captureEvent({
                message: `localSeparate: openLocalDirectory success`,
                level: 'info',
                extra: {
                  fileList
                }
            });
            setLocalPictures(fileList)
            setOpen(true)

        } catch (error) {
            if (error.code !== 20) {
                snackbarStore.showSnackbar(t(`Open local directory failed`), "warning")
                return
            }
        }
    };

    const goToLink = useCallback((e) => {
        window.open('https://www.bilibili.com/video/BV1Rt421w7eb', '_blank');
        e.stopPropagation();
    }, []);

    if (!pictures) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ mr: 2 }}
            onMouseEnter={() => setShowHelp(true)}
            onMouseLeave={() => setShowHelp(false)}
        >
             {showHelp && <Tooltip title={t("Learn how to sperate picture.")}>
                <IconButton sx={{ pl:1,pr:1 }} onClick={goToLink}>
                    <HelpOutlineIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
            </Tooltip>}
            <Button variant="outlined" size='small' color="secondary" onClick={openLocalDirectory} >
                {t('Archive')}
            </Button>
            <LocalSperaterModule onClose={onClose} open={open} dirHandle={dirHandle} localPictures={localPictures} pictures={pictures} />
        </Box>
    )
}));
export default LocalSperater;