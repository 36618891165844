import { makeObservable, observable, action } from "mobx";

class ChargeStore {
  chargeDialogOpen = false;
  cantClose = false;
  
  constructor() {
    makeObservable(this, {
        chargeDialogOpen: observable,
        openChargeDialog: action,
        closeChargeDialog: action
    });
  }

  openChargeDialog(cantClose) {
    this.chargeDialogOpen = true;
    this.cantClose = cantClose;
  }

  closeChargeDialog() {
    if( this.cantClose ) return
    this.chargeDialogOpen = false;
  }

}
const chargeStore = new ChargeStore();
export {chargeStore}
