import React, { useEffect, useState } from 'react';
import { userStore } from '../stores/UserStore';
import { useNavigate } from 'react-router-dom';
import http from '../http';
import { Box, Typography, FormControl, Button, TextField, Dialog, DialogTitle, DialogContent, InputAdornment, FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../theme';
import { inject, observer } from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { passwordRegex, phoneRegex } from '../const'
import logo from '../assets/logo.png';
import ICP from '../components/ICP';
import CaptchaInput from '../components/CaptchaInput';

function UserAgreementDialog({ open, onClose }) {
  const { t } = useTranslation();
  const [markdownText, setMarkdownText] = useState('');
  useEffect(() => {
    if (open) {
      axios.get('/agreement.md').then((res) => {
        setMarkdownText(res.data);
      });
    }
  }, [open]);
  if (!open) {
    return
  }
  if (!markdownText) {
    return <div>{t('Loading')}</div>
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("User Agreement")}</DialogTitle>
      <DialogContent>
        <ReactMarkdown>{markdownText}</ReactMarkdown>
      </DialogContent>
    </Dialog>
  );
}


const ForgetPassworPage = inject('snackbarStore')(observer(({ snackbarStore }) => {


  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState(undefined);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(undefined);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(undefined);

  const [captcha, setCaptcha] = useState(''); // 验证码
  const [captchaError, setCaptchaError] = useState(false); // 验证码是否正确
  const [captchaID, setCaptchaID] = useState(''); // 验证码 ID，用于提交验证

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);

  const [countdownTime, setCountdownTime] = useState(null);



  const [openUserAgreement, setOpenUserAgreement] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  //validations
  const validatePhone = (phone) => {
    if (!phone) {
      return t("phone num can not be empty")
    }
    if (!phoneRegex.test(phone)) {
      return t("_Phone Rule")
    }
    return '';
  }

  const validatePassword = (password) => {
    if (!password) {
      return t("password can not be empty")
    }
    if (!passwordRegex.test(password)) {
      return t("_Password Rule")
    }
    return '';
  }

  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword) {
      return t("password can not be empty")
    }
    if (confirmPassword !== password) {
      return t("Passwords do not match!")
    }
    return '';
  }

  const validateCaptcha = (captcha) => {
    if (!captcha) {
      return t("Captcha can not be empty")
    }
    if (captcha.length !== 4) {
      return t("Captcha format error")
    }
    return '';
  }

  const validateCode = (code) => {
    if (!code) {
      return t("Code can not be empty")
    }
    if (code.length !== 4) {
      return t("Code format error")
    }
    return '';
  }

  //handlers

  const handleSubmit = (event) => {
    event.preventDefault();
    let phoneError = validatePhone(phone);
    let captchaError = validateCaptcha(captcha);
    let passwordError = validatePassword(password);
    let confirmPasswordError = validateConfirmPassword(confirmPassword);
    let codeError = validateCode(code);
    setPhoneError(phoneError);
    setCaptchaError(captchaError);
    setPasswordError(passwordError);
    setConfirmPasswordError(confirmPasswordError);
    setCodeError(codeError);

    if (phoneError || captchaError || passwordError || confirmPasswordError || codeError) return;
    http.post('/forgetPassword', {
      phone,
      password,
      code,
    }).then((response) => {
      snackbarStore.showSnackbar(t('Reset password successfully'))
      navigate('/login');
    }).catch((error) => {
    });
  }


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'confirmPassword') {
      setConfirmPassword(value);
      setConfirmPasswordError(validateConfirmPassword(value));
    } else if (name === 'password') {
      setPassword(value);
      setPasswordError(validatePassword(value));
    } else if (name === 'phone') {
      setPhone(value);
      setPhoneError(validatePhone(value));
    } else if (name === 'code') {
      setCode(value);
      setCodeError(validateCode(value));
    } else if (name === 'captcha') {
      setCaptcha(value);
      setCaptchaError(validateCaptcha(value));
    }
  };

  const handleSend = (event) => {
    event.preventDefault();
    const phoneErr = validatePhone(phone);
    const captchaErr = validateCaptcha(captcha)
    setPhoneError(phoneErr);
    setCaptchaError(captchaErr);
    if (phoneErr || captchaErr) return;
    http.post('/sendCodeForReset', {
      phone,
      captchaID
    }).then((response) => {
      snackbarStore.showSnackbar(t('Send successfully'))
      setCountdownTime(60);
      const timer = setInterval(() => {
        setCountdownTime(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return null;
          }
          return prevTime - 1;
        });
      }, 1000);
    }).catch((error) => { })

  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ backgroundColor: "#ffffff", p: 5 }}>
        <Box display="flex" justifyContent="center" mb={2}>
          <img alt='logo' src={logo} style={{ color: theme.palette.primary.main, height: 145 }} />
        </Box>
        <Box>
          <Typography variant="h3" align='center' mb={2}>{t("Forget Password")}</Typography>
        </Box>
        <Box sx={{ width: 400 }}>
          <form onSubmit={handleSubmit}>
            <FormControl margin="normal" fullWidth>
              <FormLabel>{t("phone")}</FormLabel>
              <TextField
                placeholder={t("phone")}
                name='phone'
                value={phone}
                size='small'
                onChange={handleInputChange}
                error={!!phoneError}
                helperText={phoneError || undefined}
              />
            </FormControl>

            <CaptchaInput
              placeholder={t("Captcha")}
              error={!!captchaError}
              helperText={captchaError || undefined}
              onChange={handleInputChange}
              captcha={captcha}
              setCaptcha={setCaptcha}
              captchaID={captchaID}
              setCaptchaID={setCaptchaID}
            />

            <FormControl margin="normal" fullWidth>
              <FormLabel>{t("Phone code")}</FormLabel>
              <TextField
                placeholder={t("Phone code")}
                name='code'
                value={code}
                size='small'
                onChange={handleInputChange}
                error={!!codeError}
                helperText={codeError || undefined}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={handleSend}> {countdownTime !== null ? `${countdownTime} ${t(" seconds remaining")}` : t("Send Code")}</Button>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <FormLabel>{t("Password")}</FormLabel>
              <TextField
                placeholder={t("Password")}
                type="password"
                name="password"
                value={password}
                size='small'
                onChange={handleInputChange}
                error={!!passwordError}
                helperText={passwordError || undefined}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <FormLabel>{t("Confirm Password")}</FormLabel>
              <TextField
                placeholder={t("Confirm Password")}
                type="password"
                name="confirmPassword"
                size='small'
                value={confirmPassword}
                onChange={handleInputChange}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError || undefined}
              />
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
              }}
            >
              <Button variant="contained" fullWidth color="primary" type="submit">
                {t("Reset Password")}
              </Button>
            </Box>
          </form>
        </Box>

      </Box>
      <UserAgreementDialog open={openUserAgreement} onClose={() => { setOpenUserAgreement(false) }} />
      <Box sx={{ pt: 10 }}>
        <ICP />
      </Box>
    </Box>

  );
}))

export default ForgetPassworPage;