import React, { useState } from 'react';
import http from '../../http';
import withAuth from '../../HOC/withAuth';
import { Box,  Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PackageForm from './form';
import { observer,inject } from 'mobx-react';
import Back from '../../components/Back';
import theme from '../../theme';

const CreatePackageForm = inject('snackbarStore')(observer(({snackbarStore}) => {
  const [name, setName] = useState('');
  const [photoCount, setPhotoCount] = useState('');
  const [price, setPrice] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const packageData = {
      name,
      photoCount,
      price,
    };

    http.post('/user/packages/create', packageData)
      .then(response => {
        snackbarStore.showSnackbar('Package created successfully')
        navigate('/user/packages')
        // 处理成功的响应
      })
      .catch(error => { });
  };

  return (
    <Box sx={{display:"flex",flexDirection:"column",height:"100%", p:2 }} >
      <Box sx={{ display: 'flex',  alignItems: 'center', marginBottom: 2 }}>
        <Back />
        <Typography variant="h3">
          {t("Create Package")}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex',flex:1,borderRadius:1, p:10 }}>
        <PackageForm 
          handleSubmit={handleSubmit} 
          name={name} 
          setName={setName} 
          photoCount={photoCount} 
          setPhotoCount={setPhotoCount} 
          price={price} 
          setPrice={setPrice} 
        /> 
      </Box>
    </Box>


  );
}));

export default withAuth(CreatePackageForm);
