import React, { useState, useEffect } from 'react';
import http from '../../http';
import withAuth from '../../HOC/withAuth';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OrderForm from './form';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getTitle } from './fun';
import Back from '../../components/Back';
import theme from '../../theme';

const EditOrderForm = () => {
  const { orderId } = useParams();
  const [packageId, setPackageId] = useState('');
  const [packages, setPackages] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [shootLocation, setShootLocation] = useState('');
  const [remark, setRemark] = useState('');
  const [shootTime, setShootTime] = useState(dayjs());
  const [photoCount, setPhotoCount] = useState(0);

  const navigator = useNavigate()
  const { t, i18n } = useTranslation();

  useEffect(() => {
    async function fetchData(){
      let packagesResponse = await http.get('/user/packages')
      setPackages(packagesResponse.data);
      // 如果希望默认选中第一个套餐，可以设置
      if (packagesResponse.data.length > 0) {
        setPackageId(packagesResponse.data[0].id);
        setPhotoCount(packagesResponse.data[0].photoCount);
      }
      let orderResponse = await http.get(`/user/order/${orderId}`)
      let order = orderResponse.data.order
      setPackageId(order.packageId||"");
      setCustomerName(order.customerName);
      setCustomerPhone(order.customerPhone);
      setShootLocation(order.shootLocation);
      setRemark(order.remark);
      setShootTime(dayjs(order.shootTime));
      setPhotoCount(order.photoCount);
    }
    fetchData();

  }, [orderId]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let title = getTitle(i18n.language, customerName, shootLocation, shootTime)

    const orderData = {
      packageId,
      customerName,
      customerPhone,
      shootLocation,
      remark,
      shootTime,
      photoCount,
      title
    };

    http.put(`/user/order/${orderId}`, orderData)
      .then(response => {
        console.log(response);
        navigator('/user/orders')
      })
      .catch(error => { });
  };

  return (
    <Box sx={{display:"flex",flexDirection:"column", p:2 }} >
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <Back />
        <Typography variant="h3">
          {t("Edit Order")}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex',flex:1,borderRadius:1, p:10 }}>
        <OrderForm
          handleSubmit={handleSubmit}
          packages={packages}
          packageId={packageId}
          setPackageId={setPackageId}
          customerName={customerName}
          setCustomerName={setCustomerName}
          customerPhone={customerPhone}
          setCustomerPhone={setCustomerPhone}
          shootTime={shootTime}
          setShootTime={setShootTime}
          shootLocation={shootLocation}
          setShootLocation={setShootLocation}
          remark={remark}
          setRemark={setRemark}
          photoCount={photoCount}
          setPhotoCount={setPhotoCount}
        />
      </Box>
    </Box>
  );
};

export default withAuth(EditOrderForm);
