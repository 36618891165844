import { Box, Button,Snackbar,Alert,Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, {  useEffect,useState,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {readFileAsDataURL} from '../../util';
import { v4 } from 'uuid';

const PhotoUploader = inject('photoStore')(observer(({sx,size,onStartUpload,onUploaded,photoStore,albumId,type}) => {
  const { t } = useTranslation();

  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState("")
  const [errorFileName,setErrorFileName] = useState("")

  const beforeUnload = (e)=>{
      const confirmationMessage = t("Are you sure you want to leave this page? The upload processing not even be done.");
      e.returnValue = confirmationMessage;  // 兼容一些旧浏览器
      return confirmationMessage;
  }

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);

    onStartUpload(files.length);
    window.addEventListener('beforeunload', beforeUnload);
    let successFileCount = 0;
    let existFileCount = 0;
    let failFileCount = 0;
    for (let file of files) {
      let fileType = file.type;
      let fileUrl 
      // Check MIME type
      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        setMessage('Unsupported file type')
        setErrorFileName(file.name)
        setOpen(true)
        return
      }else{
        fileUrl = await readFileAsDataURL(file)
      }
      let uuid = v4();
      let status = await photoStore.addFile(fileUrl, uuid, file.name.trim(), fileType, albumId, type)
    
      if( status == "success" ){
        successFileCount++;
      }else if( status == "exist" ){
        existFileCount++;
      }else if( status == "fail" ){
        failFileCount++;
      }
    }
    onUploaded({
      pictureNum: files.length,
      successFileCount,
      existFileCount,
      failFileCount
    });
    window.removeEventListener('beforeunload', beforeUnload);

  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  return (
    <Box sx={sx}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  anchorOrigin={{ vertical:"top", horizontal:"center" }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          <Typography variant='body1'>{t(message)}</Typography>
          <Typography variant='body2'>{t('File name is:')}{errorFileName}</Typography>
        </Alert>
      </Snackbar>

      <Button variant="contained" size={size} component="label">
        {t('Upload File')}
        <input type="file" multiple hidden onChange={handleFileChange} />
      </Button>
    </Box>

  );
}));

export default PhotoUploader;
