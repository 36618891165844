import { Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Empty from './Empty';

const MemberOnly = inject('chargeStore')(observer(({  chargeStore, ...props }) => {
    const { t } = useTranslation();
    const openChargeDialog = () => {
        chargeStore.openChargeDialog()
    }
    return <Empty message={t("Member Only")} >
        <Button variant='contained' sx={{mt:2}} color='primary' onClick={openChargeDialog} >{t('Upgrade')}</Button>
    </Empty>;
}));

export default MemberOnly;
