import RgbCanvas from '../../components/RgbCanvas';
import { Box, Button, Grid, Typography, Slider,CircularProgress } from '@mui/material';
import Logo from '../../components/Logo';
import logo from '../../assets/logo.png';
import { useTranslation } from 'react-i18next';
import './imageEditor.css';
import filmBg from '../../assets/filmBg.svg';

import {snackbarStore} from '../../stores/SnackbarStore';
import iconsCamera from '../../assets/edit/icons-camera.png';
import iconsAperture from '../../assets/edit/icons-aperture.png';
import iconsShutter from '../../assets/edit/icons-shutter.png';
import iconsIso from '../../assets/edit/icons-iso.png';
import iconsLens from '../../assets/edit/icons-lens.png';

import React, { useRef, useEffect, useState, useCallback } from 'react';
import http from '../../http';

function ImageEditor() {
    const workerRef = useRef(null);
    const canvasRef = useRef(null);
    const [rbgData, setRbgData] = useState(null);
    const [currentTaskId, setCurrentTaskId] = useState(null);
    const [imageInfo, setImageInfo] = useState(null);
    const [thumbnailList, setThumbnailList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [brightness, setBrightness] = useState(0.0);
    const [contrast, setContrast] = useState(1.0);
    const [hue, setHue] = useState(0.0);
    const [saturation, setSaturation] = useState(1.0);
    const [value, setValue] = useState(1.0);
    const [temperature, setTemperature] = useState(0.0);
    const [isDragOver, setIsDragOver] = useState(false);

    const imageListRef = useRef([]);

    const addRgbData = useCallback((rgbData, taskId) => {
        const index = imageListRef.current.findIndex(item => item.taskId === taskId);
        if (index !== -1) {
            imageListRef.current[index].rgbData = rgbData;
        } else {
            imageListRef.current.push({ taskId, rgbData });
        }
        // 如果需要的话，这里可以进行额外的操作，例如触发某个状态更新来响应这个变化
    }, []);

    const addThumbnail = useCallback((thumbnailUrl, taskId) => {
        const index = imageListRef.current.findIndex(item => item.taskId === taskId);
        if (index !== -1) {
            imageListRef.current[index].thumbnailUrl = thumbnailUrl;
        } else {
            imageListRef.current.push({ taskId, thumbnailUrl });
        }
        // 如果需要的话，这里可以进行额外的操作
    }, []);

    const addImageInfo = useCallback((imageInfo, taskId) => {
        const index = imageListRef.current.findIndex(item => item.taskId === taskId);
        if (index !== -1) {
            imageListRef.current[index].imageInfo = imageInfo;
        } else {
            imageListRef.current.push({ taskId, imageInfo });
        }
        // 如果需要的话，这里可以进行额外的操作
    }, []);

    const { t } = useTranslation();

    useEffect(() => {
        workerRef.current = new Worker(new URL('../worker.js', import.meta.url));
        return () => {
            if (workerRef.current) {
                workerRef.current.terminate();
            }
        };
    }, []);

    useEffect(() => {
        if( rbgData && canvasRef.current){
            canvasRef.current.drawWithParams(brightness,contrast,hue,saturation,value,temperature)
        }
    }, [canvasRef,rbgData,brightness, contrast, hue, saturation, value,temperature]);

    useEffect(() => {
        workerRef.current.onmessage = function (e) {
            // 处理从 Worker 接收到的数据
            if(e.data.type === "imageData"){
                let rbgData = {
                    data:e.data.data,
                    width:e.data.width,
                    height:e.data.height
                }
                setRbgData(rbgData)
                setCurrentTaskId(e.data.taskId)
                addRgbData(rbgData,e.data.taskId)
                setLoading(false)
            }else if(e.data.type === "thumbnailData"){
                let thumbnailData = e.data.data
                const blob = new Blob([thumbnailData], {type: 'image/jpeg'});
                const url = URL.createObjectURL(blob);
                setThumbnailList([...thumbnailList, {
                    taskId:e.data.taskId,
                    url
                }])
                addThumbnail(url,e.data.taskId)
            }else if(e.data.type === "imageInfo"){
                let imageInfo = e.data.data
                if( imageInfo.width > 0 && imageInfo.height > 0){
                    setImageInfo(imageInfo)
                    addImageInfo(imageInfo,e.data.taskId)
                }else{
                    snackbarStore.showSnackbar(t('The image is not supported'), 'error');
                }
                setLoading(false)
                http.post('/count',{type:'images'}).then((res)=>{

                }).catch((err)=>{
            
                })
            }
        };
    }, [thumbnailList]);

    // 处理文件上传
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        const fileName = file.name;
        const arrayBuffer = await file.arrayBuffer();
        workerRef.current.postMessage({ rawData: arrayBuffer,fineName: fileName,type:"raw"  }, [arrayBuffer])
        setLoading(true)
    };

    const addFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        const fileName = file.name;
        const arrayBuffer = await file.arrayBuffer();
        workerRef.current.postMessage({ rawData: arrayBuffer,fineName: fileName,type:"raw" }, [arrayBuffer])
        setLoading(true)
    }

    const handleDragOver = (event) => {
        event.preventDefault(); 
        setIsDragOver(true);
    };
    
    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragOver(false); // 当文件离开时，取消高亮显示
      };

    const handleDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (!file) {
            return;
        }
        const fileName = file.name;
        const arrayBuffer = await file.arrayBuffer();
        workerRef.current.postMessage({ rawData: arrayBuffer,fineName: fileName,type:"raw" }, [arrayBuffer])
        setLoading(true)
    };
    
    const paperClassName = `droppableArea ${isDragOver ? 'droppableAreaHighlight' : ''}`;

    return (
        <Box sx={{display:"flex",flexDirection:"column",height:"100vh",width:"100vw",overflow:"hidden" }}>
            <Box  sx={{flexGrow:1,display:"flex",flexDirection:"row"}}>
                <Box sx={{
                    width: 200,
                    borderRight: '1px solid rgb( 245, 245, 245)',
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    pl:2,
                    pr:2
                }}>
                    <Logo sx={{
                        pt: 3,
                        pl: 3,
                    }} logo={logo} home="/user/orders" />

                    <Button variant="contained" sx={{
                        mt: 2,
                        width:"100%"
                    }} component="label">
                        {t('Add a file')}
                        <input type="file" multiple hidden onChange={addFileUpload} />
                    </Button>

                    {currentTaskId && <Button 
                        variant="contained"
                        color='secondary'
                        onClick={()=>{
                            if(rbgData){
                                canvasRef.current.getResultImage(currentTaskId,1080)
                            }
                        }}
                        sx={{
                            mt: 2,
                            width:"100%"
                        }} 
                        component="label">
                        {t('Download this image')}
                    </Button>}


                </Box>
                <Box sx={{ flex: 1,height:"100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",position:"relative"}}>
                    {!loading &&!rbgData &&
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",borderColor:"primary.main" }}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onDragLeave={handleDragLeave}
                            className={paperClassName}
                        >
                            <Typography variant="h5" sx={{ mb: 2 }}>{t('Drop files here to view and convert')}</Typography>
                            <Button variant="contained" sx={{
                                width: 200,
                            }} component="label">
                                {t('Open files from computer')}
                                <input type="file" multiple hidden onChange={handleFileUpload} />
                            </Button>
                            <Box>
                                <Grid container sx={{ mt: 1 }} spacing={3}>
                                    <Grid className='support-format' item xs={4}>CR2</Grid>
                                    <Grid className='support-format' item xs={4}>CRW</Grid>
                                    <Grid className='support-format' item xs={4}>ARW</Grid>
                                    <Grid className='support-format' item xs={4}>RAF</Grid>
                                    <Grid className='support-format' item xs={4}>RAW</Grid>
                                    <Grid className='support-format' item xs={4}>NEF</Grid>
                                    <Grid className='support-format' item xs={4}>ORF</Grid>
                                    <Grid className='support-format' item xs={4}>PEF</Grid>
                                    <Grid className='support-format' item xs={4}>X3F</Grid>
                                </Grid>
                            </Box>
                        </Box>}
                    {loading && <Box sx={{ position:"absolute",left:"50%",top:"50%",transform:"translate(-50%, -50%);", display: "flex",backgroundColor:"rgba(255,255,255,0.8)", flexDirection: "column", alignItems: "center", justifyContent: "center",p:4,borderRadius:1 }}>
                        <CircularProgress color='primary'/>
                        <Typography variant="h5">{t('Processing')}</Typography>
                    </Box>}
                    {rbgData &&<RgbCanvas ref={canvasRef} rgbData={rbgData} />}
                    
                </Box>
                <Box sx={{width:240,pt:4,pl:2,pr:2}}>
                    {imageInfo &&<Box sx={{display:"flex",flexDirection:"column"}}>
                        <Typography variant='h6' >{t("Image information")}</Typography>
                        <Box sx={{display:"flex",flexDirection:"row",mt:1,pl:1}}>
                            <img src={iconsAperture} alt="" style={{height:20,width:20}} />
                            <Typography variant='body2' sx={{ml:1}} >{imageInfo?.aperture}</Typography>
                        </Box>
                        <Box sx={{display:"flex",flexDirection:"row",mt:1,pl:1}}>
                            <img src={iconsShutter} alt="" style={{height:20,width:20}} />
                            <Typography variant='body2' sx={{ml:1}} >{imageInfo?.shutterSpeed}</Typography>
                        </Box>
                        <Box sx={{display:"flex",flexDirection:"row",mt:1,pl:1}}>
                            <img src={iconsIso} alt="" style={{height:20,width:20}} />
                            <Typography variant='body2' sx={{ml:1}} >{imageInfo?.isoSpeed}</Typography>
                        </Box>
                        <Box sx={{display:"flex",flexDirection:"row",mt:1,pl:1}}>
                            <img src={iconsLens} alt="" style={{height:20,width:20}} />
                            <Typography variant='body2' sx={{ml:1}} >{imageInfo?.focalLen}</Typography>
                        </Box>
                        <Box sx={{display:"flex",flexDirection:"row",mt:1,pl:1}}>
                            <img src={iconsCamera} alt="" style={{height:20,width:20}} />
                            <Typography variant='body2' sx={{ml:1}} >{imageInfo?.cameraMake} , {imageInfo?.cameraModel}</Typography>
                        </Box>
                    </Box>}
                    {imageInfo && <Box sx={{display:"flex",flexDirection:"column",mt:2}}>
                        <Typography variant='subtitle2' >{t("Temperature")}</Typography>
                        <Slider
                            size="small"
                            value={temperature}
                            max={1.0}
                            min={-1.0}
                            step={0.01}
                            valueLabelDisplay="auto"
                            onChange={(event, value) => {
                                setTemperature(value);
                            }}
                        />
                        <Typography variant='subtitle2' >{t("Brightness")}</Typography>
                        <Slider
                            size="small"
                            value={brightness}
                            max={1.0}
                            min={-1.0}
                            step={0.01}
                            valueLabelDisplay="auto"
                            onChange={(event, value) => {
                                setBrightness(value);
                            }}
                        />
                        <Typography variant='subtitle2' >{t("Contrast")}</Typography>
                        <Slider
                            size="small"
                            value={contrast}
                            max={2.0}
                            min={0.0}
                            step={0.01}
                            valueLabelDisplay="auto"
                            onChange={(event, value) => {
                                setContrast(value);
                            }}
                        />
                        <Typography variant='subtitle2' >{t("Hue")}</Typography>
                        <Slider
                            size="small"
                            value={hue}
                            max={1.0}
                            min={-1.0}
                            step={0.01}
                            valueLabelDisplay="auto"
                            onChange={(event, value) => {
                                setHue(value);
                            }}
                        />
                        <Typography variant='subtitle2' >{t("Saturation")}</Typography>
                        <Slider
                            size="small"
                            value={saturation}
                            max={2.0}
                            min={0.0}
                            step={0.01}
                            valueLabelDisplay="auto"
                            onChange={(event, value) => {
                                setSaturation(value);
                            }}
                        />
                        <Typography variant='subtitle2' >{t("Value")}</Typography>
                        <Slider
                            size="small"
                            value={value}
                            max={2.0}
                            min={0.0}
                            step={0.01}
                            valueLabelDisplay="auto"
                            onChange={(event, value) => {
                                setValue(value);
                            }}
                        />
                    </Box>}
                </Box>
            </Box>

            <Box sx={{ 
                height: 135,
                background : `#fff url("${filmBg}") repeat-x left top/auto 100%`
                
            }}>
                {thumbnailList.length > 0 && <Box sx={{display:"flex",padding:"20px 0px",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
                    {thumbnailList.map((thumbnail,index)=>{
                        return  <img
                            className='image-item'
                            onClick={()=>{
                                setCurrentTaskId(thumbnail.taskId)
                                setRbgData(imageListRef.current.find(item=>item.taskId === thumbnail.taskId).rgbData)
                                setImageInfo(imageListRef.current.find(item=>item.taskId === thumbnail.taskId).imageInfo)
                            }}
                            key={index} 
                            src={thumbnail.url} 
                            alt=""
                            style={{height:96,width:198,objectFit:"cover",marginRight:4 
                        }} />
                    })}
                </Box>}
            </Box>
        </Box>
    );
}

export default ImageEditor;