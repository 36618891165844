import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Empty = ({ message,minHeight=600,children }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" minHeight={minHeight} >
      <Typography variant="h3">{message}</Typography>
      {children}
    </Box>
  );
};

export default Empty;
