import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';

import defaultPicture from '../assets/default.jpg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import IconButtonWithText from './IconButtonWithText';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getSpaceUsedInUnit } from '../util';
import theme from '../theme';


const OrderInfo = ({ order, onOrderDelte, onClickShre }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <Box
      key={order.id}
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.grey[300],
        borderRadius: 2,
        overflow: "hidden",
      }}>
      <Box
        sx={{
          paddingTop: "75%",
          height: "0px",
          position: "relative",

          paddingTop: '66.66%',
          backgroundColor: theme.palette.grey[100],
          backgroundImage: `url(${order?.Album?.coverPicture?.publicUrlSmall || defaultPicture})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: 'fit', // 确保图片覆盖整个元素
          backgroundPosition: 'center' // 图片居中
        }}

        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && <Box
          sx={{
            position: "absolute",
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            top: 0,
            borderRadius: 0,
          }}
        >
          <IconButtonWithText text={t("Selection")} icon={<PhotoLibraryIcon sx={{fontSize:"1.2rem"}} />} onClick={() => {
            navigate(`/user/orders/${order.id}/step3`)
          }} />
          <Box sx={{
            position: "absolute",
            right: 5,
            top: 5,
          }}>
            <IconButton
              sx={{
                backgroundColor: "transparent",
                p: 0.5,
              }}
              onClick={() => {
                onClickShre(order)
              }} >
              <ShareIcon sx={{ color: "white", fontSize: "1.2rem" }} />
            </IconButton>

            <IconButton
              sx={{
                backgroundColor: "transparent",
                p: 0.5,
                ml:1
              }}
              onClick={() => {
                navigate(`/user/orders/${order.id}/edit`)
              }} >
              <EditIcon sx={{ color: "white", fontSize: "1.2rem" }} />
            </IconButton>

            <IconButton
              sx={{
                backgroundColor: "transparent",
                p: 0.5,
                ml:1
              }}
              onClick={() => {
                onOrderDelte(order.id)
              }} >
              <DeleteIcon sx={{ color: "white", fontSize: "1.2rem" }} />
            </IconButton>
          </Box>

        </Box>}
      </Box>
      <Box >
        <Typography variant='h6' sx={{ p: 1, overflow: "hidden", textOverflow: "ellipsis", backgroundColor: theme.palette.grey[50], borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: theme.palette.grey[300] }}>
          {order.customerName}
        </Typography>

        <Box sx={{ p: 1, backgroundColor: theme.palette.grey[50], }}>
          {order?.package && <Typography variant="body1" align='right' >
            {t('Packages')}:{order?.package?.name}
          </Typography>}
          <Typography variant="body1" align='right'  >{t("Selected pictures num is:")}{order.claimedPhotoCount}/{t("Total num is:")}{order.Album.pictureCount}</Typography>
          <Typography variant="body1" align='right' >{t("Delivery pictures num is:")}{order.Album.photoCount}</Typography>
          
          <Typography variant="body1" align='right'  >
            {t('Space Used')}:
            {order.Album.spaceUsed > 0 && getSpaceUsedInUnit(order.Album.spaceUsed)}
            {(order.Album.spaceUsed == 0 && order.Album.pictureCount > 0) && t("counting...")}
          </Typography>
          <Box sx={{ display: 'flex', mt: 1, justifyContent: "flex-end", flexDirection: "row" }}>
            {order.Album.claimed && <Box className="claimed-badge">{t("claimed")}</Box>}
            {order.Album.password && <Box className="shared-badge">{t("Shared")}</Box>}
            {order.Album.photoPassword && <Box className="claimed-badge">{t("Delivery Shared")}</Box>}
          </Box>
          {order.remark && <Box sx={{ display: 'flex', alignItems: 'center',pt:1,pl:1,mt:1, borderTopWidth:1,borderTopStyle:"dashed",borderTopColor:theme.palette.grey[300]  }}>
            <Typography variant="body1" >
              {order.remark}
            </Typography>
          </Box>}
        </Box>
      </Box>

    </Box>
  );

};

export default OrderInfo;