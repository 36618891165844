import React, { useState, useEffect } from 'react';
import http from '../../http';
import withAuth from '../../HOC/withAuth';
import { Box,  Typography } from '@mui/material';
import {useTranslation} from 'react-i18next';
import OrderForm from './form';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { getTitle } from './fun';
import { inject, observer } from 'mobx-react';
import Back from '../../components/Back';

const CreateOrderForm = inject('snackbarStore')( observer(({snackbarStore}) =>{
  const [packageId, setPackageId] = useState('');
  const [packages, setPackages] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [shootLocation, setShootLocation] = useState('');
  const [remark, setRemark] = useState('');
  const [shootTime, setShootTime] = useState(dayjs());
  const [photoCount, setPhotoCount] = useState(0);

  const navigator = useNavigate()
  const {t,i18n} = useTranslation();

  useEffect(() => {
    http.get('/user/packages')
      .then(response => {
        setPackages(response.data);
        // 如果希望默认选中第一个套餐，可以设置
        if (response.data.length > 0) {
          setPackageId(response.data[0].id);
          setPhotoCount(response.data[0].photoCount);
        }
      })
      .catch(error => {});
  }, [navigator,snackbarStore,t]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let title = getTitle(i18n.language,customerName,shootLocation,shootTime)
   
    const orderData = {
      packageId,
      customerName,
      customerPhone,
      shootLocation,
      remark,
      shootTime,
      photoCount,
      title
    };

    http.post('/user/order', orderData)
      .then(response => {
        let orderId = response.data.order.id;
        navigator('/user/orders/' + orderId + '/step3');
      })
      .catch(error => {});
  };

  return (
    <Box sx={{display:"flex",flexDirection:"column", p:2 }} >
      <Box sx={{ display: 'flex',  alignItems: 'center', marginBottom: 2 }}>
        <Back />
        <Typography variant="h3">
          {t("Create Order")}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex',flex:1, p:10 }}>
        <OrderForm
          handleSubmit={handleSubmit}
          packages={packages}
          packageId={packageId}
          setPackageId={setPackageId}
          customerName={customerName}
          setCustomerName={setCustomerName}
          customerPhone={customerPhone}
          setCustomerPhone={setCustomerPhone}
          shootTime={shootTime}
          setShootTime={setShootTime}
          shootLocation={shootLocation}
          setShootLocation={setShootLocation}
          remark={remark}
          setRemark={setRemark}
          photoCount={photoCount}
          setPhotoCount={setPhotoCount}
        />
      </Box>
    </Box>
  );
}));

export default withAuth(CreateOrderForm);
