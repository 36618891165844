import AddIcon from '@mui/icons-material/Add';
import Search from '@mui/icons-material/Search';
import { Box, Button, Grid, Alert, InputAdornment, Link, Menu, MenuItem, Select, TextField, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import withAuth from '../../HOC/withAuth';
import Empty from '../../components/Empty';
import CustomPagination from '../../components/Pagination';
import ShareDialog from '../../components/ShareDialog';
import theme from '../../theme';
import defaultPicture from '../../assets/default.jpg';
import OrderInfo from '../../components/OrderInfo';
import ShareIcon from '@mui/icons-material/Share';
import { getSpaceUsedInUnit } from '../../util';

const OrderListDeleted = inject('ordersStore', 'dialogStore', 'userStore', 'snackbarStore')(observer(({ ordersStore, dialogStore, userStore, snackbarStore }) => {


    const { t } = useTranslation();
    const [isHovered, setIsHovered] = React.useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        ordersStore.fetchOrdersDeleted()
    }, [ordersStore]);

    const handleRestore = (id) => {
        if( userStore.level == 0 && ordersStore.orders.length >= 1){
            snackbarStore.showSnackbar(t("A free account is limited to the creation of only one order. You should delete the order in the order list."),'error');
        }else{
            ordersStore.restoreOrder(id)
        }
    }

    const handleDeletePermanent = (id) => {
        ordersStore.deletePermanent(id)
    }


    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", p: 2 }} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2, height: 40 }}>
                <Typography variant="h3">
                    {t("Recycle Bin")}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "white", flex: 1, overflowY: "auto" }}>
                <Alert severity="warning" sx={{ width: "100%", mt: 2 }}>
                    {t("We will automatically delete the order in the recycle bin after 30 days.")}
                </Alert>
                {ordersStore.ordersDeleted?.length > 0 ? (
                    <Grid container spacing={2}   >
                        {ordersStore.ordersDeleted.map((order) => (
                            <Grid item sm={4} md={3} lg={2} key={order.id} >
                                <Box
                                    key={order.id}
                                    sx={{
                                        mt: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        borderColor: theme.palette.grey[300],
                                        borderRadius: 2,
                                        overflow: "hidden",
                                    }}>
                                    <Box
                                        sx={{
                                            paddingTop: "75%",
                                            height: "0px",
                                            position: "relative",

                                            paddingTop: '66.66%',
                                            backgroundColor: theme.palette.grey[100],
                                            backgroundImage: `url(${order?.Album?.coverPicture?.publicUrlSmall || defaultPicture})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: 'fit', // 确保图片覆盖整个元素
                                            backgroundPosition: 'center' // 图片居中
                                        }}

                                        onMouseEnter={() => setIsHovered(true)}
                                        onMouseLeave={() => setIsHovered(false)}
                                    >
                                    </Box>
                                    <Box >
                                        <Typography variant='h6' sx={{ p: 1, overflow: "hidden", textOverflow: "ellipsis", backgroundColor: theme.palette.grey[50], borderBottomWidth: 1, borderBottomStyle: "solid", borderBottomColor: theme.palette.grey[300] }}>
                                            {order.customerName}
                                        </Typography>

                                        <Box sx={{ p: 1, backgroundColor: theme.palette.grey[50] }}>
                                            {order?.package && <Typography variant="body1" align='right' >
                                                {t('Packages')}:{order?.package?.name}
                                            </Typography>}
                                            <Typography variant="body1" align='right'  >{t("Selected pictures num is:")}{order.claimedPhotoCount}/{t("Total num is:")}{order.Album.pictureCount}</Typography>
                                            <Typography variant="body1" align='right' >{t("Delivery pictures num is:")}{order.Album.photoCount}</Typography>

                                            <Typography variant="body1" align='right'  >
                                                {t('Space Used')}:
                                                {order.Album.spaceUsed > 0 && getSpaceUsedInUnit(order.Album.spaceUsed)}
                                                {(order.Album.spaceUsed == 0 && order.Album.pictureCount > 0) && t("counting...")}
                                            </Typography>
                                            <Box sx={{ display: 'flex', mt: 1, justifyContent: "flex-end", flexDirection: "row" }}>
                                                {order.Album.claimed && <Box className="claimed-badge">{t("claimed")}</Box>}
                                                {order.Album.password && <Box className="shared-badge">{t("Shared")}</Box>}
                                                {order.Album.photoPassword && <Box className="claimed-badge">{t("Delivery Shared")}</Box>}
                                            </Box>
                                            {order.remark && <Box sx={{ display: 'flex', alignItems: 'center', pt: 1, pl: 1, mt: 1, borderTopWidth: 1, borderTopStyle: "dashed", borderTopColor: theme.palette.grey[300] }}>
                                                <Typography variant="body1" >
                                                    {order.remark}
                                                </Typography>
                                            </Box>}
                                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
                                                <Button variant="contained" color="secondary" onClick={()=>{
                                                    handleDeletePermanent(order.id)
                                                }} > {t("Delete Permanent")} </Button>
                                                <Button variant="contained" color="primary" sx={{ml:2}} onClick={()=>{
                                                    handleRestore(order.id)
                                                }} > {t("Restore")} </Button>
                                            </Box>
                                        </Box>


                                    </Box>

                                </Box>
                            </Grid>
                        ))}
                        <ShareDialog />
                    </Grid>
                ) : (
                    <Empty message={t("No orders available.")} >
                    </Empty>
                )}

            </Box>
        </Box>
    );
}));

export default withAuth(OrderListDeleted);
