import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@mui/icons-material/Done';
import theme from '../theme';

const StepsSideBar = ({ sx, orderId, order }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  let step1, step2, step3, step4, step5, step6, step7 = false;

  if( order.customerName ){
    step1 = true;
  }
  if( order.openWatermark ){
    step2 = true;
  }
  if( order.Album?.pictureCount){
    step3 = true;
  }
  if( order.Album?.password &&  order.Album?.password.length > 0){
    step4 = true;
  }
  if( order.Album?.claimed){
    step5 = true;
  }
  if( order.Album?.photoCount && order.Album?.photoCount > 0){
    step6 = true;
  }
  if( order.Album?.photoPassword && order.Album?.photoPassword > 0){
    step7 = true;
  }

  const links = [
    {
      type:"text",
      title:t("Selection"),
    },
    {
      link: `/user/orders/${orderId}/step1`,
      num:"1.",
      label: t("Selection Setting"),
      set: step1
    },
    {
      link: `/user/orders/${orderId}/step2`,
      num:"2.",
      label: t("Watermark setting") + t("(Optional)"),
      set: step2

    },
    {
      link: `/user/orders/${orderId}/step3`,
      num:"3.",
      label: t("Picture Management"),
      set: step3
    },
    {
      link: `/user/orders/${orderId}/step4`,
      num:"4.",
      label: t("Share"),
      set: step4
    },
    {
      link: `/user/orders/${orderId}/step5`,
      sublinks: [ `/user/selections` ],
      num:"5.",
      label: t("Selection Records"),
      set: step5
    },
    {
      type:"text",
      title:t("Delivery"),
    },
    {
      link: `/user/orders/${orderId}/step6`,
      num:"1.",
      label:t("Delivery"),
      set: step6
    },
    {
      link: `/user/orders/${orderId}/step7`,
      num:"2.",
      label: t("Delivery Share"),
      set: step7
    },
  ]

  const handleNavigation = (path) => {
    navigate(path);
  };

  const matchPath = (link,sublinks) => {
    if( link === location.pathname ){
      return true;
    }
    if( sublinks && sublinks.length > 0){
      for( let i = 0; i < sublinks.length; i++){
        if( location.pathname.startsWith(sublinks[i])){
          return true;
        }
      }
    }
  };

  return (
    <Box sx={{ 
      width: 240,
      p:2,
      borderRightWidth:1,
      borderRightColor:theme.palette.grey[300],
      borderRightStyle:"solid",
      backgroundColor:theme.palette.grey[50],
      }} >
      {links.map((link,index) => (
        <Box key={index} >
          {link.type === "text" ? (<Typography className="stepTitle" variant='h5' sx={{mb:2,mt:2}}>{link.title}</Typography>) :
          <Box
            className={ matchPath(link.link,link.sublinks) ? 'active stepItem' : 'stepItem'}
            onClick={() => handleNavigation(link.link)}
          >
            <Typography variant='h5' sx={{mr:1}}>{link.num}</Typography>
            <Typography variant='subtitle2' sx={{flex:1}}>{link.label}</Typography>
            {link.set && <DoneIcon color='primary' />}
          </Box>}
        </Box>
      ))}
    </Box>
  );
};

export default StepsSideBar;