import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'mobx-react';
import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import AppSnackbar from './components/AppSnackbar';
import ForgetPasswordPage from './pages/ForgetPassworPage';
import Layout from './pages/Layout';
import LoginPage from './pages/LoginPage';
import RegesiterPage from './pages/RegesiterPage';
import OrdersSelect from './pages/customer/select';
import OrdersPhoto from './pages/customer/photo';
import ImageEditor from './pages/home/imageEditor';
import VideoEditor from './pages/home/videoEditor';
import * as stores from './stores';
import theme from './theme';

function RedirectFromRoot() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
  // 如果用户正在访问根路径，我们将他们重定向到 "/order"
    if (location.pathname === '/') {
      navigate('/user/orders');
    }
  }, [location.pathname, navigate]);

  return null;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider {...stores}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/editor" element={<ImageEditor />} />
            <Route path="/videoEditor" element={<VideoEditor />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<RegesiterPage />} />
            <Route path="/forgetPassword" element={<ForgetPasswordPage />} />
            <Route path="/customer/order/:orderId/select" element={<OrdersSelect />} />
            <Route path="/customer/order/:orderId/photo" element={<OrdersPhoto />} />
            <Route path="/*" element={<Layout />} />
          </Routes>
        </Router>
        <AppSnackbar />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
