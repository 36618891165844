
import { Box, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'

const background_colors = ["#AAAAAA", "#fdbc68", "#FF9800"]

const Level = inject('userStore', 'chargeStore')(observer(({ userStore, chargeStore }) => {
    const { t } = useTranslation()
    let level_text = t("Basic Member")
    let color = background_colors[userStore.level]
    let subscriptionEndDate = dayjs(userStore.subscriptionEndDate).format("YYYY-MM-DD")
    switch (userStore.level) {
        case 1:
            level_text = t("Intermediate Member")
            break
        case 2:
            level_text = t("Advanced Member")
            break
    }
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            pr: 5,
            pl: 5,
            cursor: "pointer",

        }}
            onClick={() => {
                chargeStore.openChargeDialog()
            }}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <Box
                    component="svg"
                    sx={{
                        width: 20,
                        height: 20,
                        mr:1,
                        '& path': {
                            fill: color, // 保证内部 path 使用 SVG 的颜色
                        },
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                >
                    <path d="M968.64 338.56a112 112 0 1 0-194.24 75.52l-108.48 185.6-86.72-266.24a135.36 135.36 0 1 0-160 0l-86.72 266.24-97.6-168.96a111.68 111.68 0 1 0-108.48 15.04l61.12 396.8a90.56 90.56 0 0 0 71.36 74.88 1603.84 1603.84 0 0 0 481.92 0 90.56 90.56 0 0 0 71.36-74.88L872.64 448a111.68 111.68 0 0 0 96-109.44z" fill="#231F20" p-id="4289"></path>
                </Box>
                <Typography variant='subtitle2' align='center'>{level_text}</Typography>
            </Box>

            {subscriptionEndDate && <Typography sx={{mt:0.5}} variant='body2' align='center'>{t("Subscription End Date")}: {subscriptionEndDate}</Typography>}
        </Box>
    )
}))

export default Level