// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './en.js';
import zhTranslations from './zh.js';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'; // 导入中文本地化包
import localeData from 'dayjs/plugin/localeData'; // 导入本地化插件

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      zh: {
        translation: zhTranslations,
      },
    },
    fallbackLng: 'zh',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });


dayjs.extend(localeData); // 使用本地化插件
dayjs.locale('zh-cn'); // 设置为中文