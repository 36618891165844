import { Box, Button, Dialog, TextField, Typography , FormLabel } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import http from '../../http';
import dayjs from 'dayjs';


const PhotoPasswordVrifyDialog = observer(({ anonymousUserStore, open, pwd, orderId, fetchOrderData  }) => {
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [cover, setCover] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [shootTime, setShootTime] = useState(null);
    const { t } = useTranslation();

    const getOrderInfo = useCallback(() => {
        http.get(`/customer/order/${orderId}/info`)
            .then(response => {
                let data = response.data
                setCover(data.photoCoverUrl)
                setCustomerName(data.customerName)
                setShootTime(data.shootTime)
            })
            .catch(error => { });
    },[])

    useEffect(() => {
        if(pwd){
            setPassword(pwd)
            verifyPasswordRequest(pwd)
        }else{
            getOrderInfo()
        }
    }, [getOrderInfo])

    const verifyPasswordRequest = useCallback(async (password)=>{
        http.post(`/customer/order/${orderId}/verifyPhotoPassword`, { password })
        .then(response => {
            anonymousUserStore.save(response.data.token)
            fetchOrderData()
        })
        .catch(error => {
            if (error.response.status === 401) {
                getOrderInfo()
                setError(true)
                setErrorMessage(t("code is wrong"))
            }
        })
    },[ orderId, t, fetchOrderData, anonymousUserStore])

    const verifyPassword = useCallback(async (event) => {
        event?.preventDefault();
        if (!password) {
            setError(true)
            setErrorMessage(t("code cannot be empty"))
        }
       await verifyPasswordRequest(password)
    }, [password, orderId, t, fetchOrderData, anonymousUserStore]);

    return (
        <Dialog 
        fullScreen
        open={open}
        keepMounted
        disablebackdropclick="true"
        PaperProps={{
          style: {
            backgroundColor: 'white',
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          },
        }}>
            <Box sx={{ p: 10 }}>
                <Typography sx={{textAlign:"center"}} variant="h6">
                    {customerName}
                </Typography>
                <Typography sx={{textAlign:"center"}} variant="body1">
                    {dayjs(shootTime).format('YYYY-MM-DD')}
                </Typography>

                <Box sx={{
                    p:1,
                    boxShadow:1,
                    margin:"20px auto",
                    maxWidth:"200px"
                }}>
                    {cover && <Box sx={{
                        height: 0,
                        paddingTop: '100%',
                        backgroundImage: `url(${cover})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}></Box>  
                }
                </Box>
                
                <form 
                    style={{
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"center",
                        alignItems:"center"
                    }}
                    onSubmit={verifyPassword}>
                    <Box sx={{ 
                        mt: 1,
                        display:"flex",
                        flexDirection:"column",
                        justifyContent:"center",
                        width:"100%",
                        maxWidth:"240px"
                    }}>
                        <FormLabel>{t("Code")}</FormLabel>
                        <TextField
                            error={!!error}
                            placeholder={t('Code')}
                            value={password}
                            helperText={error ? errorMessage : ''}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setError(false)
                                setErrorMessage(null)
                            }}
                            size='small'
                            sx={{
                                minWidth: 120
                            }}
                        />
                         <Button 
                            sx={{
                                mt:1
                            }}
                            variant="contained" 
                            color="primary"
                             type="submit"
                        >{t('Enter Code')}</Button>
                    </Box>
                </form>
            </Box>
        </Dialog>
    )
});

export { PhotoPasswordVrifyDialog };
