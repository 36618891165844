import React, { useCallback, useEffect, useState } from 'react';
import {Button,Box,LinearProgress,Typography, Select, MenuItem} from '@mui/material';
import {MediaCodecHandler} from '../encoder';
import { inject, observer } from 'mobx-react';
import {v4} from 'uuid';

import { useTranslation } from 'react-i18next';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import {getSpaceUsedInUnit} from '../../util';
import { DataStream } from 'mp4box';
import http from '../../http';

const FileSelector = ({ onFileSelected }) => {
  const {t} = useTranslation();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onFileSelected(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        id="file-input"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <label htmlFor="file-input">
        <Button startIcon={<OndemandVideoIcon />} variant="contained" component="span">
          {t("Select a Video from computer")}
        </Button>
      </label>
    </div>
  );
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ width: '100%',display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%',mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Task({fileName,totalSize,onClickPreview,onClickProcess,onClickStop,processing}){
  return (
    <Box sx={{ display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
      <Box  sx={{flexGrow:1,display:"flex",flexDirection:"column",justifyContent:"center"}}>
        <Typography variant="subtitle1">{fileName}</Typography>
        <Typography variant="body2">转换后预估大小{getSpaceUsedInUnit(totalSize)}</Typography>
      </Box>
    {!processing && <Button variant='contained' size='small' color='secondary' onClick={onClickPreview}>预览</Button>}
    {!processing && <Button sx={{ml:1}} variant='contained' size='small' onClick={onClickProcess}>执行</Button>}
    {processing && <Button color='secondary' sx={{ml:1}} variant='outlined' size='small' onClick={onClickStop}>停止</Button>}
  </Box>
  )
}

const VideoEditor = inject('snackbarStore')(observer(({ snackbarStore }) => {
  const [videoPercent, setVideoPercent] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [previewLink, setPreviewLink] = useState('');
  const [processing, setProcessing] = useState(false);
  const {t} = useTranslation();
  const [targetSize, setTargetSize] = useState(0);
  const [targetBitrate, setTargetBitrate] = useState(1000);
  const [fileName, setFileName] = useState('');
  const decodeWorker = React.useRef(null);

  const encoder = React.useRef(null);


  const targetFrameRate = 30
  const targetSampleRate = 44100
  const targetChannels = 1
  const fitType = 1 // "fill" , "contain" , "cover"

  const totalSize = React.useMemo(() => {
    return (targetBitrate + 50) / 8 * totalLength * 1024
  }, [totalLength,targetBitrate]);

  const videoProgress = useCallback((percent) => {
    let percentInt = parseInt(percent*100)
    if(percentInt > 100){
      percentInt = 100
    }
    setVideoPercent(percentInt)
  }, []);

  const audioProgress = useCallback((percent) => {
    let percentInt = parseInt(percent*100)
    if(percentInt > 100){
      percentInt = 100
    }
    //setAudioPercent(percentInt)
  }, []);
  const done = useCallback(() => {
    var stream = new DataStream();
    stream.endianness = DataStream.BIG_ENDIAN;
    encoder.current?.outputFile.file.write(stream);
    var blob = new Blob([stream.buffer], { type: 'video/mp4' });
    var url = URL.createObjectURL(blob);
    setPreviewLink(url)
    setProcessing(false)
    setVideoPercent(100)
    http.post('/count',{type:'videos'}).then((res)=>{

    }).catch((err)=>{

    })
  }, []);

  useEffect(() => {
    decodeWorker.current = new Worker(new URL('../decoder.worker.js', import.meta.url));

    const frameDuration = 1 / targetFrameRate * 1000; // 毫秒
    let frameNumber = 0;
    encoder.current = new MediaCodecHandler(videoProgress, audioProgress,done);
    decodeWorker.current.postMessage({ command: 'init' });
    decodeWorker.current.onmessage = (e) => {
      const { command, params } = e.data;
      if (command === 'videoInfo') {
        const { outWidth, outHeight, skipFrames,rotate, videoExtraData, useWebcodec,videoCodecId,videoTotalFrames,audioTotalFrames } = params;
        encoder.current.codecParams(
            outWidth,
            outHeight,
            skipFrames,
            targetFrameRate,
            targetSampleRate,
            targetChannels,
            videoExtraData,
            useWebcodec,
            videoCodecId,
            rotate,
            videoTotalFrames,
            audioTotalFrames
        );
      }
      if (command === 'handleVideoTotalLength') {
        setTotalLength(params?.totalLength)
        encoder.current.setTotalLength(params?.totalLength);
      }
      if (command === 'endInfo') {
        const {isBreak} = params;
        if(isBreak){
          setProcessing(false)
        }
        encoder.current.endInfo();
      }

      if (command === 'h264Packet') {
        const { h264Data, keyframe, timestamp, duration } = params;
        encoder.current.h264Packet(h264Data, keyframe, timestamp, duration);
      }

      if (command === 'pcmData') {
        const { pcmData, samples, channels, sampleRate } = params;
        encoder.current.pcmData(pcmData, samples, channels, sampleRate);
      }

      if (command === 'yuvFrame') {
        const { yuvData,width,height } = params;
        const imageData = new ImageData(new Uint8ClampedArray(yuvData), width, height);
        const offscreenCanvas = new OffscreenCanvas(width, height);
        const ctx = offscreenCanvas.getContext('2d');
        ctx.putImageData(imageData, 0, 0);
        const timestamp = frameNumber * frameDuration;
        const frame = new VideoFrame(offscreenCanvas, {timestamp : timestamp, duration : frameDuration});
        frameNumber++;
        let keyFrame = frameNumber % targetFrameRate == 0;
        encoder.current.yuvFrame(frame, keyFrame);
      }
    }
    return () => {
      decodeWorker.current.terminate();
    };
  },[])

  const handleFileSelected = (file) => {
      if( processing){
        snackbarStore.showSnackbar('有一个视频正在执行中', 'error');
        return
      }
      let name = "picsect_" + file.name.split('.').shift();
      setFileName(name)
      setTasks([{
        fileName:file.name,
      }])
      //decodeWorker.postMessage({ command: 'process', params: { file, fileName, targetWidth,targetHeight,targetFrameRate,fitType } });
      decodeWorker.current.postMessage({ command: 'getInfo', params: { file,fileName:file.name } });
  };
  
  const onSelectSize = (event) => {
    const size = event.target.value;
    setTargetSize(size);
  }

  const onChangeBitrate = (event) => {
    const bitrate = event.target.value;
    encoder.current.setTargetBitrate(bitrate);
    setTargetBitrate(bitrate);
  }

  const onClickPreview = (task) => {
    setPreviewLink('')
    setProcessing(true)
    decodeWorker.current.postMessage({ command: 'preview', params: { file:task.file,fileName:task.fileName, targetSize,targetSize,targetFrameRate,fitType } });
  }

  const onClickProcess = (task) => {
    setPreviewLink('')
    setProcessing(true)
    decodeWorker.current.postMessage({ command: 'process', params: { file:task.file,fileName:task.fileName, targetSize,targetSize,targetFrameRate,fitType } });
  }

  const onClickStop = (task) => {
    decodeWorker.current.postMessage({ command: 'stop'});
    encoder.current.stop();
  }

  const handleDownload = useCallback(() => {
    const a = document.createElement('a');
    a.href = previewLink;
    a.download = fileName+".mp4"; // 设置下载的文件名
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(previewLink); // 清理内存中的引用
  }, [previewLink,fileName]);

  return (
    <Box sx={{height:"100vh",width:"100vw",display:"flex",flexDirection:"column",alignItems:"center",pt:30}}>
      <Typography variant="h2" color="primary">视频瘦身助手</Typography>
      <Typography variant="body1" color="textSecondary">输出格式为:mp4</Typography>
      <Typography variant="body1" color="textSecondary">支持格式:mp4,avi,mkv,flv,mov,webm</Typography>
      <Box sx={{display:"flex",flexDirection:"row",mt:3}}>
        <FileSelector onFileSelected={handleFileSelected} />
        <Select sx={{ml:1}} size='small' onChange={onSelectSize} value={targetSize} >
          <MenuItem value={0}>原始</MenuItem>
          <MenuItem value={640}>640P</MenuItem>
          <MenuItem value={720}>720P</MenuItem>
          <MenuItem value={1080}>1080P</MenuItem>
          <MenuItem value={1920}>1920P</MenuItem>
        </Select>
        <Select sx={{ml:1}} size='small' onChange={onChangeBitrate} value={targetBitrate} >
          <MenuItem value={350}>最低</MenuItem>
          <MenuItem value={500}>低</MenuItem>
          <MenuItem value={1000}>标准</MenuItem>
          <MenuItem value={2000}>清晰</MenuItem>
          <MenuItem value={3000}>超清</MenuItem>
        </Select>
      </Box>
      <Box sx={{ width: '800px' ,mt:2}}>
        {
          tasks.map((task,index) => {
            return <Task 
            key={v4()} 
            fileName={task.fileName} 
            totalSize={totalSize} 
            processing={processing} 
            onClickPreview={()=>{onClickPreview(task)}} 
            onClickProcess={()=>{onClickProcess(task)}}
            onClickStop={()=>{onClickStop(task)}}
            
            />
          })
        }
      </Box>
      <Box sx={{ width: '800px' , display:"flex", flexDirection:"column",alignItems:"center",justifyContent:"center" }}>
        {videoPercent >0 && <LinearProgressWithLabel value={videoPercent} />}
        {previewLink && <video  style={{marginTop:10}} width="100%" src={previewLink} controls></video>}
        {previewLink && <Button sx={{mt:1}} color='primary' variant='contained' size='small' onClick={handleDownload}>下载</Button>}
      </Box>
    </Box>
  );
}));

export default VideoEditor;
