import { observer, inject } from 'mobx-react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';


const AppSnackbar = inject('snackbarStore')(observer(({ snackbarStore }) => {
  const {t} = useTranslation()
  return <Snackbar
    open={snackbarStore.open}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    autoHideDuration={3000}
    onClose={snackbarStore.closeSnackbar}>
    <MuiAlert elevation={6} variant="filled" onClose={snackbarStore.closeSnackbar} severity={snackbarStore.severity}>
      {snackbarStore.message && t(snackbarStore.message)}
    </MuiAlert>
  </Snackbar>
}));

export default AppSnackbar;
