// SnackbarStore.js
import { makeObservable,observable,action } from 'mobx';

class SnackbarStore {
  open = false;
  message = '';
  severity = 'success';

  constructor() {
    makeObservable(this, {
      open: observable,
      message: observable,
      severity: observable,
      showSnackbar: action,
      closeSnackbar: action,
    });
  }

  showSnackbar = (message, severity = 'success') => {
    this.open = true;
    this.message = message;
    this.severity = severity;
  };

  closeSnackbar = () => {
    this.open = false;
  };
}
const snackbarStore = new SnackbarStore();

export {snackbarStore};
