import { Alert, Box, Button, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import withAuth from '../../../HOC/withAuth';
import MemberOnly from '../../../components/MemberOnly';
import StepsSideBar from '../../../components/StepsSideBar';
import http from '../../../http';
import theme from '../../../theme';

import DeleteIcon from '@mui/icons-material/Delete';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import CustomTabLabel from '../../../components/CustomTabLabel';
import IconButtonWithText from '../../../components/IconButtonWithText';
import ImagePreviewDialog from '../../../components/ImagePreviewDialog';
import OnUploadedConfirmDialog from '../../../components/OnUploadedConfirmDialog';
import PhotoUploader from '../PhotoUploader';

const ImageGallery = observer(({ photo, orderId, isCover, albumId }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();
    return (<Grid item sm={2}  >
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.grey[300],
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
                height: 0,
                paddingTop: '66.66%',
                backgroundColor: theme.palette.grey[50],
                backgroundImage: `url(${photo.publicUrlSmall})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'contain', // 确保图片覆盖整个元素
                backgroundPosition: 'center' // 图片居中
            }}
        >
            {photo.progress >= 0 && photo.progress < 100 && <div style={{
                position: 'absolute',
                top: "50%",
                left: "50%",
                width: "120px",
                height: "60px",
                fontSize: "60px",
                lineHeight: "60px",
                marginLeft: "-60px",
                marginTop: "-30px",
                textAlign: "center",
                color: "white"
            }}>{photo.progress}%</div>}
            {isHovered && <HoverHandles orderId={orderId} albumId={albumId} photo={photo} />}
            {/* cover */}
            {isCover && <Box sx={{
                position: "absolute",
                left: "-20px",
                top: "5px",
                width: "80px",
                textAlign: "center",
                color: 'white',
                backgroundColor: theme.palette.primary.main,
                fontSize: "0.75rem",
                rotate: "-45deg",
                zIndex: 10,
                p: 1,
            }}
            > {t("Cover")} </Box>}

        </Box>
        <Box sx={{
            display: {
                xs: 'flex',
                sm: 'flex',
            }, justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
            borderTop: 0
        }} >
            <Typography variant='body1'>
                {photo?.originalFileName ? photo?.originalFileName : photo.fileName}
            </Typography>
        </Box>
    </Grid>)
})

const HoverHandles = inject('photoStore')(observer(({ photoStore, orderId, albumId, photo }) => {
    const { t } = useTranslation();
    const handlePrviewClick = () => {
        photoStore.setCurrentPhoto(photo)
        return
    };
    const handleSetAsCover = () => {
        http.put(`/user/albums/${albumId}/setAsCover`, {
            photoCover: photo.id
        }).then(response => {
            photoStore.setCover(photo.id);
        }).catch(error => { });
    }
    return (
        <Box sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 100
        }}>
            <IconButtonWithText text={t("Preview")} icon={<PhotoLibraryIcon sx={{ fontSize: "1.2rem" }} />} onClick={handlePrviewClick} />

            <Box sx={{
                position: "absolute",
                right: 5,
                top: 5,
            }}>
                <Button
                    variant="contained"
                    color='secondary'
                    size='small'
                    onClick={handleSetAsCover} >
                    {t("Set as cover")}
                </Button>

                <IconButton
                    sx={{
                        backgroundColor: "transparent",
                        p: 0.5,
                        ml: 1
                    }}
                    onClick={() => {
                        photoStore.deletePhoto(orderId, [photo.id])
                    }} >
                    <DeleteIcon sx={{ color: "white", fontSize: "1.2rem" }} />
                </IconButton>
            </Box>

        </Box>
    )
}))

const types = ["retouching", "negative"]

const Step6 = inject('snackbarStore', 'photoStore', 'userStore')(observer(({ snackbarStore, photoStore, userStore }) => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogContent, setConfirmDialogContent] = useState("")
    const [typeIndex, setTypeIndex] = useState(0); // negative 底片  retouching 精修

    const { t } = useTranslation();

    const fetchOrderData = useCallback(() => {
        http.get(`/user/order/${orderId}`)
            .then(response => {
                setOrder(response.data?.order);
                photoStore.setCover(response.data?.order?.Album?.photoCover)
                photoStore.setPhotos(response.data?.photos);
                photoStore.setType(types[0]);
            })
            .catch(error => { });
    }, [orderId]);

    const handleChange = (event, newValue) => {
        setTypeIndex(newValue);
        photoStore.setType(types[newValue]);
    }

    useEffect(() => {
        fetchOrderData();
    }, []);

    if (!order) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }} >
            <StepsSideBar order={order} sx={{ width: 200 }} orderId={orderId} />
            <Box sx={{ flex: 1, display: 'flex', flexDirection: "column" }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    pt: 3,
                    pb: 3,
                    pl: 4,
                    pr: 4,
                    borderBottom: 1,
                    borderBottomColor: theme.palette.grey[300],
                    borderBottomStyle: "solid",
                    backgroundColor: theme.palette.grey[50],
                }}>
                    <Typography variant="h3"> {t("Delivery")}</Typography>
                </Box>
                {userStore.level < 1 ? <MemberOnly /> : <Box sx={{ p: 2, flex: 1, display: 'flex', flexDirection: "column" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Tabs sx={{ flexGrow: 1 }} value={typeIndex} onChange={handleChange}>
                            <Tab label={<CustomTabLabel label={t("retouching")} num={photoStore.getPhotCount("retouching")} />} />
                            <Tab label={<CustomTabLabel label={t("negative")} num={photoStore.getPhotCount("negative")} />} />
                        </Tabs>
                        <PhotoUploader
                            size={"small"}
                            albumId={order?.Album?.id}
                            type={types[typeIndex]}
                            onStartUpload={(num) => {
                                snackbarStore.showSnackbar(t("Uploading {{num}} pictures", { num: num }))
                            }}
                            onUploaded={({
                                pictureNum,
                                successFileCount,
                                existFileCount,
                                failFileCount
                            }) => {
                                setConfirmDialogContent(t("Uploaded {{pictureNum}} pictures, seccess: {{successFileCount}} ,fail: {{failFileCount}},exists {{existFileCount}}.",
                                    {
                                        pictureNum: pictureNum,
                                        successFileCount: successFileCount,
                                        existFileCount: existFileCount,
                                        failFileCount: failFileCount
                                    }))
                                setConfirmDialogOpen(true)
                            }} />

                    </Box>
                    <Alert severity="info" sx={{ width: "100%", mt: 1 }}>
                        {t("The number of pictures in the negative folder of the delivery is limited to {{num}}.", { num: userStore.limit })}
                    </Alert>
                    <Box sx={{ flexGrow: 1, mt: 2 }}>

                        {photoStore.filtedPhotos?.length > 0 && <Grid container spacing={2}>
                            {photoStore.filtedPhotos?.map((photo) => {
                                return <ImageGallery isCover={photo.id == photoStore.photoCover} albumId={order?.Album?.id} orderId={orderId} key={photo.id} photo={photo} />
                            })}
                        </Grid>}
                    </Box>
                </Box>}

            </Box>
            <ImagePreviewDialog />
            <OnUploadedConfirmDialog open={confirmDialogOpen} title={t("Upload Complete")} onConfirm={() => {
                setConfirmDialogOpen(false)
            }} >
                {confirmDialogContent}
            </OnUploadedConfirmDialog>
        </Box>
    );
}));

export default withAuth(Step6);
