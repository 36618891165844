
import { Box, Button, Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography, IconButton ,Switch} from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState,createRef } from 'react';
import { useTranslation } from 'react-i18next';
import http from '../http';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {copyImageToClipboard} from '../util'
import orders from '../pages/orders';

const ShareDialog = inject('snackbarStore', 'ordersStore')(observer(({ ordersStore, snackbarStore }) => {
  const { t } = useTranslation();
  const [expire, setExpire] = useState(7);
  const [isShared, setIsShared] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordType, setPasswordType] = useState('random')
  const [passwordError, setPasswordError] = useState(false)
  const [miniprogrameQrCodeUrl, setMiniprogrameQrCodeUrl] = useState('')
  const [autoFill, setAutoFill] = useState(false)
  const [url, setUrl] = useState('')

  useEffect(() => {
    if (!ordersStore.currentOrder) return;
    let password = ordersStore.currentOrder?.Album?.password || ""
    if (password.length > 0) {
      setIsShared(true)
      setPassword(password)
      http.get(`/user/order/${ordersStore.currentOrder?.id}/qrCode`).then((response) => {
        let imageData = response.data.imageData
        setMiniprogrameQrCodeUrl(imageData)
      })
    } 
  }, [ordersStore.currentOrder, setPassword])

  useEffect(() => {
    const baseUrl = `${window.location.origin}/customer/order/${ordersStore.currentOrder?.id}/select`;
    if( autoFill ){
      setUrl(`${baseUrl}?pwd=${password}`)
    }else{
      setUrl(baseUrl)
    }
  }, [ordersStore,autoFill,password])

  if (ordersStore.currentOrder == null) return;

  const passwordRegex = /^[A-Za-z0-9]{4}$/;
  
  const orderId = ordersStore.currentOrder.id

  const copyToClipboard = async () => {
    try {
      // 使用 Clipboard API
      let totalText = ""
      if( !autoFill ){
        totalText += `${t('Link')}: ${url}  ${t('Code')}: ${password}`
      }else{
        totalText = url
      }
      await navigator.clipboard.writeText(totalText);
      snackbarStore.showSnackbar(t('Text copied to clipboard'))
    } catch (err) {
      snackbarStore.showSnackbar(t('Failed to copy text: '), 'error')
    }
  };
  const handleClose = () => {
    ordersStore.setCurrentOrder(null)
  }

  const cancelShare = () => {
    http.get(`/user/orders/${orderId}/cancelShare`)
      .then(response => {
        setIsShared(false)
        setPassword("")
        setPasswordType('random')
        setExpire(7)
        setMiniprogrameQrCodeUrl("")
        snackbarStore.showSnackbar(t('Cancel Share'))
        const newOrder = response?.data
        ordersStore.setCurrentOrder(newOrder)
        ordersStore.fetchOrders()
      })
      .catch(error => { });
  }

  const validatePassword = (password) => {
    if (!password || !passwordRegex.test(password)) {
      return t('Code must be 4 numbers')
    }
    return '';
  }

  const generateCode = () => {
    //4位随机数
    let code = Math.random() * 10000
    code = code.toFixed(0)
    if (code.length < 4) {
      code = code.padStart(4, '0')
    }
    return code
  }

  const saveInfo = (event) => {

    if (!orderId) {
      event.preventDefault();
      return false;
    }
    
    const newPassword = passwordType === 'random' ? generateCode() : password

    if (validatePassword(newPassword)) {
      setPasswordError(true)
      return;
    }

    http.post(`/user/orders/${orderId}/setShare`, { password: newPassword, expire: expire })
      .then(orderResponse => {
        http.get(`/user/order/${ordersStore.currentOrder?.id}/qrCode`).then((response) => {
          setIsShared(true)
          setPassword(newPassword)
          let imageData = response.data.imageData
          setMiniprogrameQrCodeUrl(imageData)
          snackbarStore.showSnackbar(t('Save Share'))
          const newOrder = orderResponse?.data
          ordersStore.setCurrentOrder(newOrder)
          ordersStore.fetchOrders()
          
        })
      })
      .catch(error => { });
  }


  return (
    <Dialog
      open={ordersStore.currentOrder != null}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogContent>
        <Typography variant="h6">{t('Share')}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {!isShared && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'flex-start', mt: 2 }}>
          <FormControl component="fieldset" sx={{ display: 'flex', flexGrow: 1, flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
            <FormLabel component="span" sx={{ width: 80 }} >{t("Expire")}</FormLabel>
            <RadioGroup
              aria-label="options"
              name="expire"
              value={expire}
              row={true}
              onChange={(e) => { setExpire(e.target.value) }}
            >
              <FormControlLabel value="7" control={<Radio />} label={t('7 days')} />
              <FormControlLabel value="14" control={<Radio />} label={t('14 days')} />
              <FormControlLabel value="30" control={<Radio />} label={t('30 days')} />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" sx={{ display: 'flex', flexGrow: 1, flexDirection: "row", justifyContent: "center", alignItems: 'flex-start' }}>
            <FormLabel component="span" sx={{ width: 80, marginTop: "10px" }} >{t("Code")}</FormLabel>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'flex-start' }}>
              <FormControlLabel
                value="random"
                checked={passwordType === 'random'}
                control={<Radio />}
                label={t('random')}
                labelPlacement="end"
                onChange={(e) => {
                  const value = e.target.value
                  setPasswordType(value)
                  //generateCode()
                }}
              />
              <Box sx={{ marginLeft: "-11px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Radio
                  checked={passwordType === 'manul'}
                  value="manul"
                  onChange={(e) => {
                    setPasswordType(e.target.value)
                  }}
                />
                <TextField
                  size='small'
                  id="password"
                  value={password}
                  error={!!passwordError}
                  onChange={(e) => {
                    const value = e.target.value
                    if( value.length <= 4){
                      setPassword(value)
                      setPasswordError(validatePassword(value));
                    }

                  }}
                  disabled={passwordType === 'random'}
                  helperText={passwordError}
                />
                <Typography
                  sx={{ ml: 1 }}
                  variant="body2"
                >{t("Code must be 4 numbers")}</Typography>
              </Box>
            </Box>

          </FormControl>


        </Box>}
        { isShared && !miniprogrameQrCodeUrl  && <Box sx={{p:3}} >Loading..</Box> }
        {isShared && miniprogrameQrCodeUrl && <Box sx={{  display: "flex", flexDirection: "row", justifyContent: "center" }} >
          <Box sx={{ flex:1, display: "flex", flexDirection: "column", justifyContent: "center",pr:2,borderRight:"1px dashed #efefef" }}>
            <FormControl sx={{ mt: 2 }}>
              <FormLabel>{t("Shared Link")}</FormLabel>
              <TextField
                placeholder={t("Shared Link")}
                value={url}
                size="small"
                multiline={true}
              />
            </FormControl>
            <FormControl sx={{ mt: 2 }}>
              <TextField
                size='small'
                label={t("Code")}
                value={password}
                disabled={true}
                sx={{ width: 100 }}
              />
            </FormControl>
            <FormControlLabel
                sx={{ mt: 2 }}
                name='autofill'
                control={<Switch checked={autoFill} onChange={(e)=>{ setAutoFill(e.target.checked) }} />}
                label={t("Auto fill password")}
            />
            <Button sx={{ mt: 1,width:200 }} variant="outlined" color="secondary" onClick={copyToClipboard}>{t('Copy url and password to clipboard')}</Button>
          </Box>
          <Box sx={{flex:1,  display: "flex", flexDirection: "column", alignItems:"center", justifyContent: "center",pl:2 }}>
            <Typography variant="body1" align='center' sx={{ mt: 1 }}>分享到小程序</Typography>
            <Box sx={{ display: "flex",mt:1, flexDirection: "row", justifyContent: "center" }}>
              <img alt="微信小程序二维码" style={{ height: 200, width: 200 }} src={`data:image/png;base64,${miniprogrameQrCodeUrl}`} />
            </Box>
            <Button sx={{ mt: 1,width:200 }} variant="outlined" color="secondary" onClick={()=>{
              if(copyImageToClipboard(miniprogrameQrCodeUrl)){
                snackbarStore.showSnackbar(t('Copy image to clipboard'))
              }else{
                snackbarStore.showSnackbar(t('Failed to copy image to clipboard'), 'error')
              
              }
            }}>{t('Copy image to clipboard')}</Button>
       
          </Box>
        </Box>}

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}>
          {!isShared && <Button sx={{ ml: 1 }} variant="contained" color="primary" onClick={saveInfo}>{t('Save Share')}</Button>}
          {isShared && <Button sx={{ ml: 1 }} variant="contained" color="primary" onClick={cancelShare}>{t('Cancel Share')}</Button>}
        </Box>
      </DialogContent>
    </Dialog>
  )
}))

export default ShareDialog;