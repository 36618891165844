import axios from 'axios';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { v4 } from 'uuid';
import http from '../http';
import { userStore } from './UserStore';
import { readFileAsDataURL, resizeImage } from '../util'

class PhotoStore {
  photos = []; // 保存文件信息
  currentPhoto = null; // 当前图片

  currentType = "retouching"
  photoCover = null

  constructor() {
    makeObservable(this, {
      currentType: observable,
      photos: observable,
      currentPhoto: observable,
      photoCover:observable,
      uploadToServer: action,
      setPhotos: action,
      setCurrentPhoto: action,
      setType: action,
      deletePhotoWithName: action,
    });
  }

  setCover(pictureId) {
    this.photoCover = pictureId
  }

  setType(type) {
    this.currentType = type
  }

  get filtedPhotos() {
    return this.photos.filter(f => f.type === this.currentType)
  } 

  getPhotCount(type) {
    return this.photos.filter(f => f.type === type).length
  }

  setCurrentPhoto(photo) {
    this.currentPhoto = photo;
  }

  setPhotos(photos) {
    this.photos = photos;
  }

  async addFile(fileUrl, uuid, originalFileName, fileType, albumId,type) {
    if( this.getPhotCount("negative") >= userStore.limit ){
      return "fail"
    }
    let fileName = originalFileName.replace(/\.[^/.]+$/, ".jpg");
    let existPicture = this.photos.find(f => f.originalFileName === originalFileName)
    if(existPicture){
      return "exist"
    }
    this.photos.unshift({
      id: uuid,
      publicUrlSmall: fileUrl,
      type: type,
      progress: -1,
    })
    const { blobOrigin, blob, blobSmall } = await resizeImage(fileUrl,
      userStore.maxDimension,
      false,
      "",
      "",
      0,
      "",
      0,
      true)
    return await this.uploadToServer(uuid, fileName,originalFileName, albumId, fileType, blobOrigin, blob, blobSmall, type)

  }

  async uploadToServer(uuid, fileName,originalFileName, albumId, fileType, blobOrigin, blob, blobSmall, type) {
   
    let fileSize = blob.size;
    let fileSizeSmall = blobSmall.size;
    let originalFileSize = blobOrigin.size;
    
    const response = await http.post('/user/photo/addToAlbum', {
      originalFileName: originalFileName,
      uploadOriginalFile: true,
      fileName: fileName,
      albumId: albumId,
      fileSize: fileSize,
      fileSizeSmall: fileSizeSmall,
      originalFileSize: originalFileSize,
      type: type,
    });
    const { ifExist, url, photo, urlSmall, urlOriginal } = response.data

    if (ifExist) {
      this.photos = this.photos.filter(f => f.id !== uuid)
      return "exist"
    }

    const index = this.photos.findIndex(f => f.id === uuid);
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.updateProgress(uuid, percentCompleted);
      },
      headers: {
        'Content-Type': fileType
      }
    };
    await axios.put(urlOriginal, blobOrigin, config);

    await axios.put(urlSmall, blobSmall, {
      headers: {
        'Content-Type': fileType
      }
    });


    await axios.put(url, blob, {
      headers: {
        'Content-Type': fileType
      }
    });

    this.photos[index] = photo

    return "success"
  }

  updateProgress(uuid, progress) {
    const photo = this.photos.find(f => f.id === uuid);
    if (photo) {
      photo.progress = progress;
    }
  }

  async deletePhoto(orderId, photoIds){
    const response = await http.post('/user/photo/delete', { orderId:orderId ,photoIds: photoIds });
    if(response.data.success){
      photoIds.forEach(photoIds => {
        this.photos = this.photos.filter(f => f.id !== photoIds)
      });
      this.multiSelections = []
    }
   
  }

  async deletePhotoWithName(orderId, photoNames){
    let photoIds = []
    this.photos.forEach(photo => {
      if(photoNames.includes(photo.originalFileName)){
        photoIds.push(photo.id)
      }
    });
    if(photoIds.length > 0){
      const response = http.post('/user/photo/delete', { orderId:orderId ,photoIds: photoIds });
      if(response.success){
          this.photos = this.photos.filter(f => f.id !== photo.id)
      }
    }
  }


  reset() {
    this.photos = []
  }
}

const photoStore = new PhotoStore();

export { photoStore };
