import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography, Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Subscription = inject('userStore', 'chargeStore','snackbarStore')(observer(({ userStore, chargeStore,snackbarStore }) => {

    const { t } = useTranslation();

    const navigator = useNavigate()

    const openChargeDialog = () => {
        chargeStore.openChargeDialog()
    }

    const toSubscriptionsList = () => {
        navigator('/user/subscriptions')
    }

    return <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="body2" color="text.secondary" align="left">
            {t('Subscription end date')} <br />
            <Typography sx={{fontSize:"0.8rem"}} variant="span" color="text.secondary" align="left">{dayjs(userStore.subscriptionEndDate).format("YYYY-MM-DD")}</Typography>
        </Typography>
        <Box>
            <Button size='small' onClick={openChargeDialog}>{t("Upgrade")}</Button>
            <Button size='small' onClick={toSubscriptionsList}>{t("Subscriptions List")}</Button>
        </Box>
    </Box>
}))

export default Subscription;
