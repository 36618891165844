import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, Card, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import withAuth from '../../HOC/withAuth';
import Empty from '../../components/Empty';
import http from '../../http';
import theme from '../../theme';

function InfoItem({ label, content }) {
  return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" component="div" sx={{width:140,textAlign:"right",pr:1}}>
          {label} :
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{flex:1,textAlign:"left"}} component="p">
          {content}
        </Typography>
    </Box>
  );
}


const SubscriptionCallback = inject()(observer(() => {
  const { t } = useTranslation();
  const [booking,setBooking] = useState(null)
  const [subscriptionStatus,setSubscriptionStatus] = useState(null)
  const [out_trade_no,setOut_trade_no] = useState(null)

  const checkStatus = useCallback( () => {
    if(out_trade_no){
      http.get(`/user/subscription/query/${out_trade_no}`).then(res => {
        setBooking(res.data.booking)
        setSubscriptionStatus(res.data.booking.status)
      })
    }
  },[out_trade_no])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const out_trade_no = params.get('out_trade_no');
    setOut_trade_no(out_trade_no)
    checkStatus()
  },[checkStatus])

  const getStatusIcon =(subscriptionStatus) => {
    const sx={
      fontSize:'5rem',
      color:theme.palette.primary.main
    }
    if(subscriptionStatus==="completed"){
      return <CheckCircleIcon sx={sx} />
    }else if(subscriptionStatus==="pending"){
      return <WarningIcon sx={sx}  />
    }else{
      return <CancelIcon  sx={sx}/>
    }
  }

  const getStatusButton = (subscriptionStatus) => {
    if(subscriptionStatus==="pending"){
      return <Button variant="contained" color="secondary" onClick={() => {
        checkStatus()
      }}>{t("Refresh Status")} </Button>
    }
  }

  return (

    <Box sx={{display:"flex",flexDirection:"column",height:"100%", p:2 }} >
      {
        booking?<Box sx={{display:"flex",width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
          <Card sx={{p:5}}>
            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",mb:5}}>
              {getStatusIcon(subscriptionStatus)}
            </Box>
            <InfoItem label={t("Order Number")} content={booking.id} />
            <InfoItem label={t("Subscription Time")} content={`${dayjs(booking.startDate).format("YYYY-MM-DD")} - ${dayjs(booking.endDate).format("YYYY-MM-DD")}`} />
            <InfoItem label={t("Duration")} content={`${booking.duration} ${t("Month")}`} />
            <InfoItem label={t("Subscription payment status")} content={t(booking.status)} />
            <InfoItem label={t("Amount")} content={`¥${booking.amount}`} />
            <InfoItem label={t("Alipay Order Number")} content={booking.trade_no} />
            <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",mt:5}}>
              {getStatusButton(subscriptionStatus)}
            </Box>
        </Card>
        </Box>:<Empty message={t("No such subscription")} />
      }
    </Box>
  );
}));

export default withAuth(SubscriptionCallback);
