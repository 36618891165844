import React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Dialog, Button, DialogContentText } from '@mui/material';
import { useTranslation } from 'react-i18next';


const DeleteConfirmationDialog = inject('dialogStore')(observer(({ dialogStore }) => {
  const { isOpen, closeDialog, confirmAndCloseDialog } = dialogStore;
  const {t} = useTranslation()
  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <Box sx={{ p: 3 }}>
        <DialogContentText sx={{pb:3}} variant="h6" >
          {dialogStore.text&&t(dialogStore.text)}
        </DialogContentText>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
          <Button variant="contained" color="secondary" onClick={closeDialog} sx={{mr:1}} >
            {t('Cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={confirmAndCloseDialog} >
            {t('Confirm')}
          </Button>
        </Box>

      </Box>

    </Dialog>
  );
}));

export default DeleteConfirmationDialog;
