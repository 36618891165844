import { makeObservable, observable, action } from "mobx";

class DialogStore {
  isOpen = false;
  confirmCallback = null;
  text = ""
  
  constructor() {
    makeObservable(this, {
      isOpen: observable,
      confirmCallback: observable,
      text: observable,
      openDialog: action,
      closeDialog: action,
      confirmAndCloseDialog: action,
    });
  }

  openDialog = (text,confirmCallback) => {
    this.isOpen = true;
    this.text = text
    this.confirmCallback = confirmCallback;
  }

  closeDialog = () => {
    this.text = ""
    this.isOpen = false;
  }

  confirmAndCloseDialog = () => {
    if (this.confirmCallback) {
      this.confirmCallback();
    }
    this.text = ""
    this.closeDialog();
  }
}
const dialogStore = new DialogStore();
export {dialogStore}
