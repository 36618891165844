import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, TextField, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import withAuth from '../../../HOC/withAuth';
import StepsSideBar from '../../../components/StepsSideBar';
import http from '../../../http';
import { copyImageToClipboard, getShareInfo } from '../../../util';
import theme from '../../../theme';

const Step4 = inject('snackbarStore')(observer(({ snackbarStore }) => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [expire, setExpire] = useState(7);
    const [expiredDays, setExpiredDays] = useState(0)
    const [isShared, setIsShared] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordType, setPasswordType] = useState('random')
    const [passwordError, setPasswordError] = useState(false)
    const [miniprogrameQrCodeUrl, setMiniprogrameQrCodeUrl] = useState('')
    const [autoFill, setAutoFill] = useState(false)
    const [url, setUrl] = useState('')
    const routerNavigator = useNavigate()


    const handleInputChange = (event) => {
        let value = event.target.value;
        const name = event.target.name;

    };

    const { t, i18n } = useTranslation();

    const generateCode = () => {
        //4位随机数
        let code = Math.random() * 10000
        code = code.toFixed(0)
        if (code.length < 4) {
            code = code.padStart(4, '0')
        }
        return code
    }

    const passwordRegex = /^[A-Za-z0-9]{4}$/;
    const validatePassword = (password) => {
        if (!password || !passwordRegex.test(password)) {
            return t('Code must be 4 numbers')
        }
        return '';
    }

    const fetchOrderData = useCallback(() => {
        http.get(`/user/order/${orderId}`)
            .then(orderResponse => {
                let orderData = orderResponse.data?.order;
                setOrder(orderData);
                let password = orderData.Album?.password || ""
                if (password.length > 0) {
                    setIsShared(true)
                    setPassword(password)
                    setExpire(orderData.Album?.expire)
                    setExpiredDays(orderData.Album?.expiredTime)
                    http.get(`/user/order/${orderId}/qrCode`).then((response) => {
                        let imageData = response.data.imageData
                        setMiniprogrameQrCodeUrl(imageData)
                    })
                }
            })
            .catch(error => { });
    }, [orderId]);

    const saveInfo = useCallback(() => {
        const newPassword = passwordType === 'random' ? generateCode() : password
        if (validatePassword(newPassword)) {
            setPasswordError(true)
            return;
        }

        http.post(`/user/orders/${orderId}/setShare`, { password: newPassword, expire: expire })
            .then(orderResponse => {
                let newOrderData = orderResponse.data
                setOrder(newOrderData)
                setExpiredDays(newOrderData.Album?.expiredTime)
                http.get(`/user/order/${orderId}/qrCode`).then((response) => {
                    setIsShared(true)
                    setPassword(newPassword)
                    let imageData = response.data.imageData
                    setMiniprogrameQrCodeUrl(imageData)
                    snackbarStore.showSnackbar(t('Save Share'))

                })
            })

    }, [orderId, expire, password, passwordType, snackbarStore, t]);

    const cancelShare = () => {
        http.get(`/user/orders/${orderId}/cancelShare`)
            .then(response => {
                let newOrderData = response.data
                setOrder(newOrderData)
                setIsShared(false)
                setPassword("")
                setPasswordType('random')
                setExpire(7)
                setMiniprogrameQrCodeUrl("")
                snackbarStore.showSnackbar(t('Cancel Share'))
            })
            .catch(error => { });
    }

    const copyToClipboard = async () => {
        try {
            // 使用 Clipboard API
            let totalText = ""
            if (!autoFill) {
                totalText += `${t('Link')}: ${url}  ${t('Code')}: ${password}`
            } else {
                totalText = url
            }
            await navigator.clipboard.writeText(totalText);
            snackbarStore.showSnackbar(t('Text copied to clipboard'))
        } catch (err) {
            snackbarStore.showSnackbar(t('Failed to copy text.'), 'error')
        }
    };


    useEffect(() => {
        fetchOrderData();
    }, [fetchOrderData]);

    useEffect(() => {
        const baseUrl = `${window.location.origin}/customer/order/${orderId}/select`;
        if (autoFill) {
            setUrl(`${baseUrl}?pwd=${password}`)
        } else {
            setUrl(baseUrl)
        }
    }, [autoFill, password])

    if (!order) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }} >
            <StepsSideBar order={order} sx={{ width: 200 }} orderId={orderId} />
            <Box sx={{ flex: 1, display: 'flex', flexDirection: "column" }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    pt: 3,
                    pb: 3,
                    pl: 4,
                    pr: 4,
                    borderBottom: 1,
                    borderBottomColor: theme.palette.grey[300],
                    borderBottomStyle: "solid",
                    backgroundColor: theme.palette.grey[50],
                }}>
                    <Typography variant="h3"> {t("Share")}</Typography>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: "row", alignItems: "flex-start",mt:2, justifyContent: "center" }}>
                    <Box sx={{ width: 600, display: 'flex', flexDirection: "column" }}>
                        {!isShared && <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'flex-start', mt: 2 }}>

                            <FormControl component="fieldset" sx={{ display: 'flex', flexGrow: 1, flexDirection: "row", justifyContent: "center", alignItems: 'center' }}>
                                <FormLabel component="span" sx={{ width: 80 }} >{t("Expire")}</FormLabel>
                                <RadioGroup
                                    aria-label="options"
                                    name="expire"
                                    value={expire}
                                    row={true}
                                    onChange={(e) => { setExpire(e.target.value) }}
                                >
                                    <FormControlLabel value="7" control={<Radio />} label={t('7 days')} />
                                    <FormControlLabel value="14" control={<Radio />} label={t('14 days')} />
                                    <FormControlLabel value="30" control={<Radio />} label={t('30 days')} />
                                </RadioGroup>
                            </FormControl>

                            <FormControl component="fieldset" sx={{ display: 'flex', flexGrow: 1, flexDirection: "row", justifyContent: "center", alignItems: 'flex-start' }}>
                                <FormLabel component="span" sx={{ width: 80, marginTop: "10px" }} >{t("Code")}</FormLabel>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: 'flex-start' }}>
                                    <FormControlLabel
                                        value="random"
                                        checked={passwordType === 'random'}
                                        control={<Radio />}
                                        label={t('random')}
                                        labelPlacement="end"
                                        onChange={(e) => {
                                            const value = e.target.value
                                            setPasswordType(value)
                                            //generateCode()
                                        }}
                                    />
                                    <Box sx={{ marginLeft: "-11px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                        <Radio
                                            checked={passwordType === 'manul'}
                                            value="manul"
                                            onChange={(e) => {
                                                setPasswordType(e.target.value)
                                            }}
                                        />
                                        <TextField
                                            size='small'
                                            id="password"
                                            value={password}
                                            error={!!passwordError}
                                            onChange={(e) => {
                                                const value = e.target.value
                                                if (value.length <= 4) {
                                                    setPassword(value)
                                                    setPasswordError(validatePassword(value));
                                                }

                                            }}
                                            disabled={passwordType === 'random'}
                                            helperText={passwordError}
                                        />
                                        <Typography
                                            sx={{ ml: 1 }}
                                            variant="body2"
                                        >{t("Code must be 4 numbers")}</Typography>
                                    </Box>
                                </Box>
                            </FormControl>
                        </Box>}
                        {isShared && <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
                            <Box>
                                <Typography variant="h6">{t("Share detail")}</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                    <Typography>{t("Expire")}{expire}{t("days")}</Typography>
                                    <Typography sx={{ ml: 1 }}>{getShareInfo(expiredDays)}</Typography>
                                </Box>

                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }} >
                                <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", pr: 2, borderRight: "1px dashed #efefef" }}>
                                    <FormControl sx={{ mt: 2 }}>
                                        <FormLabel>{t("Shared Link")}</FormLabel>
                                        <TextField
                                            placeholder={t("Shared Link")}
                                            value={url}
                                            size="small"
                                            multiline={true}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ mt: 2 }}>
                                        <TextField
                                            size='small'
                                            label={t("Code")}
                                            value={password}
                                            disabled={true}
                                            sx={{ width: 100 }}
                                        />
                                    </FormControl>
                                    <FormControlLabel
                                        sx={{ mt: 2 }}
                                        name='autofill'
                                        control={<Switch checked={autoFill} onChange={(e) => { setAutoFill(e.target.checked) }} />}
                                        label={t("Auto fill password")}
                                    />
                                    <Button sx={{ mt: 1, width: 200 }} variant="outlined" color="secondary" onClick={copyToClipboard}>{t('Copy url and password to clipboard')}</Button>
                                </Box>
                                <Box sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", pl: 2 }}>
                                    <Typography variant="body1" align='center' sx={{ mt: 1 }}>分享到小程序</Typography>
                                    <Box sx={{ display: "flex", mt: 1, flexDirection: "row", justifyContent: "center" }}>
                                        {miniprogrameQrCodeUrl && <img alt="微信小程序二维码" style={{ height: 200, width: 200 }} src={`data:image/png;base64,${miniprogrameQrCodeUrl}`} />}
                                    </Box>
                                    <Button sx={{ mt: 1, width: 200 }} variant="outlined" color="secondary" onClick={() => {
                                        if (copyImageToClipboard(miniprogrameQrCodeUrl)) {
                                            snackbarStore.showSnackbar(t('Copy image to clipboard'))
                                        } else {
                                            snackbarStore.showSnackbar(t('Failed to copy image to clipboard'), 'error')
                                        }
                                    }}>{t('Copy image to clipboard')}</Button>

                                </Box>
                            </Box>
                        </Box>
                        }

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2,
                            }}
                        >
                            {!isShared && <Button sx={{ ml: 1 }} variant="contained" color="primary" onClick={saveInfo}>{t('Save Share')}</Button>}
                            {isShared && <Button sx={{ ml: 1 }} variant="contained" color="secondary" onClick={cancelShare}>{t('Cancel Share')}</Button>}


                        </Box>
                    </Box>
                </Box>

            </Box>

        </Box>
    );
}));

export default withAuth(Step4);
