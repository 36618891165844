import { Box, Typography, Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';


const MultiHandles = observer(({ picture, isSelected, handlePictureSelect }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: isSelected ? "rgba(255,255,255,0.3)" : "transparent",
      cursor: "pointer"
    }}
      onClick={() => {
        handlePictureSelect(picture.id, !isSelected)
      }}
    >
       {isSelected ? <CheckCircleIcon
        color='primary'
        sx={{
          position: "absolute",
          right: "2px",
          bottom: "2px",
          p: 0,
          borderRadius: "50%", backgroundColor: "white"
        }}/> : <RadioButtonUncheckedIcon
        color='primary'
        sx={{
          position: "absolute",
          right: "2px",
          bottom: "2px",
          p: 0,
          borderRadius: "50%", backgroundColor: "white"
        }} />}
    </Box>
  )
})


const CustomerImageGallery = observer(({ picture, multiMode, isSelected, showImageName, handlePictureSelect, handlePreview }) => {

  const { t } = useTranslation();

  return (
    <Grid item xs={4} sm={3} md={2} lg={1}>
      <Box
        sx={{
          position: "relative",
          height: 0,
          paddingTop: '133%',
          backgroundImage: `url(${picture.publicUrlSmall})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: 'cover', // 确保图片覆盖整个元素
          backgroundPosition: 'center', // 图片居中
          cursor: "pointer",
        }}
        onClick={() => {
          handlePreview();
        }}
      >
        {/* multi opration */}
        {multiMode && <MultiHandles picture={picture} isSelected={isSelected} handlePictureSelect={handlePictureSelect} />}

        {/* select picture */}
        {!multiMode && isSelected && <Box sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          fontSize: "0.75rem",
          p: 0.5,
          position: 'absolute',
          right: "0px",
          top: "0px"
        }}  >{t("Selected")}</Box>}

        {picture.progress >= 0 && picture.progress < 100 && <div style={{
          position: 'absolute',
          top: "50%",
          left: "50%",
          width: "120px",
          height: "60px",
          fontSize: "60px",
          lineHeight: "60px",
          marginLeft: "-60px",
          marginTop: "-30px",
          textAlign: "center",
          color: "white"
        }}>{picture.progress}%</div>}
         {picture.progress == 100 && <div style={{
          position: 'absolute',
          top: "50%",
          left: "50%",
          width: "120px",
          height: "60px",
          fontSize: "21px",
          lineHeight: "60px",
          marginLeft: "-60px",
          marginTop: "-30px",
          textAlign: "center",
          color: "white"
        }}>{t('Download completed')}</div>}
      </Box>
      {showImageName && <Box sx={{
        display: {
          xs: 'flex',
          sm: 'flex',
        }, justifyContent: 'space-between', alignItems: 'center', marginBottom: 1, p: 1, backgroundColor: theme.palette.secondary.main
      }} >
        <Typography variant="body3" color="white">{picture.fileName}</Typography>
      </Box>}
    </Grid>
  );
})

export { CustomerImageGallery };
