import React, { useState, useEffect, useCallback, useRef } from 'react';
import http from '../../../http';
import withAuth from '../../../HOC/withAuth';
import { Box, Typography, FormControl, FormLabel, TextField, FormControlLabel, RadioGroup, Radio, Slider, Button, Switch, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OrderForm from '../form';
import { useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { getTitle } from '../fun';
import Back from '../../../components/Back';
import StepsSideBar from '../../../components/StepsSideBar';
import { InputAdornment } from '@mui/material';
import { countChineseAndEnglish } from '../../../util';
import { set } from 'mobx';
import WatermarkPreview from '../../../components/WatermarkPreview';
import theme from '../../../theme';


const Step2 = inject('snackbarStore', 'userStore')(observer(({ snackbarStore, userStore }) => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const navigator = useNavigate()
    const { t, i18n } = useTranslation();
    const [openWatermark, setOpenWatermark] = useState(false);
    const [watermark, setWatermark] = useState("");
    const watermarkPreviewRef = useRef(null);
    const [watermarkError, setWatermarkError] = useState(false);
    const [position, setPosition] = useState("top-right");
    const [watermarkStyle, setWatermarkStyle] = useState("single");
    const [watermarkAlpha, setWatermarkAlpha] = useState(0.5);
    const [fontSize, setFontSize] = useState(35)

    const validateWatermark = (watermark) => {
        if (watermark == null || watermark == '') {
            return t("Watermark can not be empty")
        }
        if (countChineseAndEnglish(watermark) > 5) {
            return t("Watermark must less than 5 letters")
        }
        return '';
    }

    const handleInputChange = (event) => {
        let { name, value } = event.target;
        if (name == "watermark") {
            setWatermark(value);
            setWatermarkError(validateWatermark(value));
        } else if (name == "openWatermark") {
            value = event.target.checked;
            setOpenWatermark(value);
        } else if (name == "position") {
            setPosition(value);
        } else if (name == "fontSize") {
            setFontSize(value);
        } else if (name == "style") {
            setWatermarkStyle(value);
        } else if (name == "alpha") {
            setWatermarkAlpha(value);
        }
    };

    const fetchOrderData = useCallback(() => {
        http.get(`/user/order/${orderId}`)
            .then(response => {
                setOrder(response.data?.order);
                let openWatermark = response.data?.order?.openWatermark;
                if (openWatermark) {
                    let orderWatermark = response.data?.order?.watermark;
                    let orderWatermarkPosition = response.data?.order?.watermarkPosition;
                    let orderWatermarkFontSize = response.data?.order?.watermarkFontSize;
                    let orderWatermarkStyle = response.data?.order?.watermarkStyle;
                    let orderWatermarkAlpha = parseFloat(response.data?.order?.watermarkAlpha);

                    setOpenWatermark(openWatermark);
                    setWatermark(orderWatermark);
                    setPosition(orderWatermarkPosition);
                    setFontSize(orderWatermarkFontSize);
                    setWatermarkStyle(orderWatermarkStyle);
                    setWatermarkAlpha(orderWatermarkAlpha);
                }

            })
            .catch(error => { });
    }, [orderId]);
    const save = useCallback(() => {
        let watermarkError = validateWatermark(watermark);
        if (watermarkError) {
            setWatermarkError(watermarkError);
            return;
        }
        http.put(`/user/order/${orderId}`, {
            watermark,
            openWatermark,
            watermarkPosition: position,
            watermarkFontSize: fontSize,
            watermarkStyle: watermarkStyle,
            watermarkAlpha: watermarkAlpha
        }).then(response => {
            let newOrder = response.data;
            setOrder(newOrder);
            snackbarStore.showSnackbar(t("Update Success"));
        })

    }, [orderId, openWatermark, watermark, position, fontSize, watermarkStyle, watermarkAlpha]);


    useEffect(() => {
        fetchOrderData();
    }, [fetchOrderData]);

    useEffect(() => {
        if (watermarkPreviewRef.current) {
            watermarkPreviewRef.current.drawWatermark(
                openWatermark,
                watermarkStyle,
                watermark,
                watermarkAlpha,
                fontSize,
                position);
        }
    }, [watermarkStyle, openWatermark, watermark, watermarkAlpha, fontSize, position])

    if (!order) {
        return <div>Loading...</div>;
    }

    return (

        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }} >
            <StepsSideBar order={order} sx={{ width: 200 }} orderId={orderId} />
            <Box sx={{ flex: 1, display: 'flex', flexDirection: "column" }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    pt: 3,
                    pb: 3,
                    pl: 4,
                    pr: 4,
                    borderBottom: 1,
                    borderBottomColor: theme.palette.grey[300],
                    borderBottomStyle: "solid",
                    backgroundColor: theme.palette.grey[50],
                }}>
                    <Typography variant="h3"> {t("Watermark setting")}</Typography>
                    <Alert severity="warning" sx={{mt:2,width:"100%"}}>{t("The watermark will be enabled when the next file is uploaded.")}</Alert>
                </Box>
                <Box sx={{ flex: 1,mt:2, display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Box sx={{ width: 600, mt: 1, display: 'flex', flexDirection: "column" }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <WatermarkPreview width="600" height="400" ref={watermarkPreviewRef} />
                        </Box>
                        <FormControlLabel
                            name='openWatermark'
                            control={<Switch checked={openWatermark} onChange={handleInputChange} />}
                            label={t("OpenWatermark")}
                        />
                        {openWatermark && <Box sx={{ display: 'flex', flexDirection: "column" }}>
                            <FormControl sx={{ mt: 3 }}>
                                <FormLabel>{t("Watermark")}</FormLabel>
                                <TextField
                                    size="small"
                                    placeholder={t("Watermark")}
                                    type="text"
                                    name="watermark"
                                    value={watermark}
                                    onChange={handleInputChange}
                                    error={!!watermarkError}
                                    helperText={watermarkError}
                                />
                            </FormControl>
                            <FormControl sx={{ mt: 3 }}>
                                <FormLabel>{t("Style")}</FormLabel>
                                <RadioGroup
                                    row
                                    name="style"
                                    value={watermarkStyle}
                                >
                                    <FormControlLabel value="single" control={<Radio />} label={t("Single")} onChange={handleInputChange} />
                                    <FormControlLabel value="fill" control={<Radio />} label={t("Fill")} onChange={handleInputChange} />
                                </RadioGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel>{t("Alpha")}</FormLabel>
                                <Slider
                                    sx={{ width: 300 }}
                                    max={1}
                                    min={0}
                                    value={watermarkAlpha}
                                    step={0.1}
                                    name='alpha'
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            {watermarkStyle == "single" && <Box sx={{ mt: 3 }}>
                                <FormControl>
                                    <FormLabel>{t("Position")}</FormLabel>
                                    <RadioGroup
                                        row
                                        name="position"
                                        value={position}
                                    >
                                        <FormControlLabel value="top-left" control={<Radio />} label={t("Top Left")} onChange={handleInputChange} />
                                        <FormControlLabel value="top-right" control={<Radio />} label={t("Top Right")} onChange={handleInputChange} />
                                        <FormControlLabel value="bottom-left" control={<Radio />} label={t("Bottom Left")} onChange={handleInputChange} />
                                        <FormControlLabel value="bottom-right" control={<Radio />} label={t("Bottom Right")} onChange={handleInputChange} />
                                        <FormControlLabel value="center" control={<Radio />} label={t("Center")} onChange={handleInputChange} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>}
                            <Box sx={{ mt: 3 }}>
                                <FormControl>
                                    <FormLabel>{t("Font Size")}</FormLabel>
                                    <RadioGroup
                                        row
                                        name="fontSize"
                                        value={fontSize}
                                    >
                                        <FormControlLabel value="25" control={<Radio />} label={t("Small")} onChange={handleInputChange} />
                                        <FormControlLabel value="35" control={<Radio />} label={t("Medium")} onChange={handleInputChange} />
                                        <FormControlLabel value="55" control={<Radio />} label={t("Large")} onChange={handleInputChange} />
                                        <FormControlLabel value="85" control={<Radio />} label={t("X-Large")} onChange={handleInputChange} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2,
                            }}
                        >
                            <Button onClick={save} variant="contained" color="primary" type="submit">
                                {t("Save")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>

    );
}));

export default withAuth(Step2);
