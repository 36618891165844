import { Box,Typography,Grid } from '@mui/material';
import { inject, observer } from 'mobx-react';
import StepsSideBar from '../../components/StepsSideBar';
import withAuth from '../../HOC/withAuth';
import React, {  useEffect, useState, useCallback } from 'react';
import http from '../../http';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import  Empty  from '../../components/Empty';
import  LocalSperater  from '../../components/LocalSperater';
import  CopyNames  from '../../components/CopyNames';


const Selections = inject()(observer(() => {
   
    const [order, setOrder] = useState(null);
    const [pictures, setPictures] = useState([]);

    const { t } = useTranslation();

    const selectionId = useParams().selectionId;

    const fetchSelections = useCallback(() => {
        http.get(`/user/selection/${selectionId}`)
            .then(response => {
                let order = response.data?.order;
                let pictures = response.data?.pictures;
                setPictures(pictures);
                setOrder(order);

            })
            .catch(error => { });
    },[selectionId]);

    useEffect(() => {
        fetchSelections();
    }, []);

    if (!order) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }} >
            <Box sx={{ flex: 1, display: 'flex', flexDirection: "row", marginBottom: 2 }}>
                <StepsSideBar order={order} sx={{ width: 200 }} orderId={order.id} />
                <Box sx={{ flex: 1, display: 'flex', flexDirection: "column" }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        pt: 3,
                        pb: 3,
                        pl: 4,
                        pr: 4,
                        borderBottom: 1,
                        borderBottomColor: theme.palette.grey[300],
                        borderBottomStyle: "solid",
                        backgroundColor: theme.palette.grey[50],
                    }}>
                        <Typography variant="h3"> {t("Selection Records")}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            pt: 1,
                            pb: 1,
                            pl: 4,
                            pr: 4,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.grey[300],
                            borderBottomStyle: "solid",
                        }}
                    >
                        <CopyNames pictures={pictures} />
                        <LocalSperater pictures={pictures} />
                      
                    </Box>
                    <Box sx={{ flex: 1,p: 2, display: 'flex', flexDirection: "row", alignItems: "flex-start",  justifyContent: "flex-start" }}>
                        {pictures?.length > 0 ? <Grid container spacing={2}>
                                {pictures.map((picture,index) => {
                                    return  <Grid key={index} item sm={2}  >
                                         <Box
                                            sx={{
                                                borderWidth: "1px",
                                                borderStyle: "solid",
                                                borderColor: theme.palette.grey[300],
                                                borderRadius: "10px",
                                                overflow: "hidden",
                                                position: "relative",
                                                height: 0,
                                                paddingTop: '66.66%',
                                                backgroundColor: theme.palette.grey[50],
                                                backgroundImage: `url(${picture.publicUrlSmall})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: 'contain', // 确保图片覆盖整个元素
                                                backgroundPosition: 'center' // 图片居中
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    bottom: 0,
                                                    width: "100%",
                                                    backgroundColor: "rgba(0,0,0,0.7)",
                                                    p:1
                                                }}
                                            >
                                                <Typography
                                                    variant="body3"
                                                    sx={{ color: "white" }}
                                                >{picture.originalFileName}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                })}
                            </Grid> : <Empty message={t("No picture available.")} />}
                    </Box>
                </Box>

            </Box>
        </Box>
    );
}));

export default withAuth(Selections);
