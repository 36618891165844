import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography, Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const SubscriptionAlert = inject('userStore', 'chargeStore','snackbarStore')(observer(({ userStore, chargeStore,snackbarStore }) => {

    const { t } = useTranslation();

    useEffect(() => {
        if( !userStore.subscriptionEndDate ) return
        let expireDay = dayjs(userStore.subscriptionEndDate)
        let now = dayjs()
        if ( now.isAfter(expireDay) ) {
            chargeStore.openChargeDialog(true)
            snackbarStore.showSnackbar(t(`Your subscribe is expired`), "error")
        }else{
            let diffHours = expireDay.diff(now, 'hour')
            if( diffHours < 24 ) {
                snackbarStore.showSnackbar(t(`You have {{hours}} hours left to use this platform.`,{ hours: diffHours }), "warning")
            }else{
                let diff = expireDay.diff(now, 'day')
                if( diff < 10 ) {
                    snackbarStore.showSnackbar(t(`You have {{days}} days left to use this platform.`,{ days: diff }), "warning")
                }
            }
        }
        
    }, [userStore.subscriptionEndDate,chargeStore,snackbarStore,t])

    return <Box> </Box>
}))

export default SubscriptionAlert;
