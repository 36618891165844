import axios from 'axios';
import {userStore} from './stores/UserStore';
import {snackbarStore} from './stores/SnackbarStore';
import {translate} from './util'
import { redirect } from 'react-router-dom';

let baseURL = 'http://localhost:8080'

if( process.env.NODE_ENV === 'production' ){
  baseURL = 'https://api.picsect.com'
}

const http = axios.create({
  baseURL
});

export {baseURL}

http.interceptors.request.use((config) => {
  const userRoutesPattern = /^\/user\/.*/;
  if (userRoutesPattern.test(config.url)) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// 添加一个响应拦截器
http.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  if( error.code === "ERR_NETWORK"){
    userStore.logout();
  }
  // 对响应错误做点什么
  if (error.response && error.response.status === 403) {
    userStore.logout();
  }
  if (!error.response){
    snackbarStore.showSnackbar(error.message,'error');
  }

  if ( error.response ){
    snackbarStore.showSnackbar(translate(error.response.data.error),'error');
    return Promise.reject(error);
  }
  
  return Promise.reject(error);
})
export default http;
