import React, { useState, useEffect, useCallback } from 'react';
import http from '../../../http';
import withAuth from '../../../HOC/withAuth';
import { Box, Typography, FormControl, FormLabel, TextField, FormControlLabel, Switch, Button, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OrderForm from '../form';
import { useNavigate, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { getTitle } from '../fun';
import Back from '../../../components/Back';
import StepsSideBar from '../../../components/StepsSideBar';
import { InputAdornment } from '@mui/material';
import { isNumber } from '../../../util';
import theme from '../../../theme';

const Step1 = inject('snackbarStore')(observer(({ snackbarStore }) => {
    const { orderId } = useParams();
    const [order, setOrder] = useState({});
    const [photoCount, setPhotoCount] = useState(0);
    const [photoCountError, setPhotoCountError] = useState('');
    const [forceLimit, setForceLimit] = useState(true);
    const [pricePerPic, setPricePerPic] = useState(0);
    const [pricePerPicError, setPricePerPicError] = useState('');
    const [showImageName, setShowImageName] = useState(false);


    const validatePhotoCount = (photoCount) => {
        if (!photoCount) {
            return t("photoCount can not be empty")
        }
        if (!isNumber(photoCount)) {
            return t("photoCount must be number")
        }
        if (photoCount > 1000) {
            return t("photoCount must be less than 1000")
        }
        return '';
    }

    const validatePricePerPic = (pricePerPic) => {
        if (!pricePerPic) {
            return t("pricePerPic can not be empty")
        }
        if (!isNumber(pricePerPic)) {
            return t("pricePerPic must be number")
        }
    }


    const handleInputChange = (event) => {
        let value = event.target.value;
        const name = event.target.name;
        if (name === 'photoCount') {
            setPhotoCount(value);
            let photoCountError = validatePhotoCount(value);
            setPhotoCountError(photoCountError);
            if (photoCountError) {
                return;
            }
        } else if (name === 'showImageName') {
            value = event.target.checked;
            setShowImageName(value);
        } else if (name === 'forceLimit') {
            value = event.target.checked;
            setForceLimit(value);
        } else if (name === 'pricePerPic') {
            setPricePerPic(value);
            let pricePerPicError = validatePricePerPic(value);
            setPricePerPicError(pricePerPicError);
            if (pricePerPicError) {
                return;
            }
        }


    };

    const navigator = useNavigate()
    const { t, i18n } = useTranslation();

    const fetchOrderData = useCallback(() => {
        http.get(`/user/order/${orderId}`)
            .then(response => {
                setOrder(response.data?.order);
                setPhotoCount(response.data?.order?.photoCount)
                setShowImageName(response.data?.order?.showImageName)
                setForceLimit(response.data?.order?.forceLimit)
                setPricePerPic(response.data?.order?.pricePerPic || 0)

            })
            .catch(error => { });
    }, [orderId]);

    const save = useCallback(() => {
        let photoCountError = validatePhotoCount(photoCount);
        setPhotoCountError(photoCountError);
        if (photoCountError) {
            return;
        }
        if (!forceLimit) {
            let pricePerPicError = validatePricePerPic(pricePerPic);
            setPricePerPicError(pricePerPicError);
            if (pricePerPicError) {
                return;
            }
        }

        http.put(`/user/order/${order.id}`, {
            photoCount,
            forceLimit,
            pricePerPic,
            showImageName,
        }).then(response => {
            snackbarStore.showSnackbar(t("Update Success"));
        })
            .catch(error => { });

    }, [orderId, photoCount, showImageName, forceLimit, pricePerPic, navigator]);

    useEffect(() => {
        fetchOrderData();
    }, [fetchOrderData]);

    if (!order) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }} >
            <StepsSideBar order={order} sx={{ width: 200 }} orderId={orderId} />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: "column" }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    pt: 3,
                    pb: 3,
                    pl: 4,
                    pr: 4,
                    borderBottom: 1,
                    borderBottomColor: theme.palette.grey[300],
                    borderBottomStyle: "solid",
                    backgroundColor: theme.palette.grey[50],
                }}>
                    <Typography variant="h3"> {t("Selection Setting")}</Typography>
                    {order.Album?.claimed && <Alert severity="warning" sx={{mt:2,width:"100%"}}>{t("Select setting can't be modified if user selected.")}</Alert>}
                </Box>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: "column",alignItems:"center" }}>
                    <Box sx={{ width: 400, display: 'flex', flexDirection: "column", mt:4 }}>
                        <FormControl margin="normal" fullWidth>
                            <FormLabel>{t("selectable images")} *</FormLabel>
                            <TextField
                                disabled={order?.Album?.claimed}
                                size="small"
                                placeholder={t("selectable images")}
                                name='photoCount'
                                value={photoCount}
                                onChange={handleInputChange}
                                error={!!photoCountError}
                                helperText={photoCountError || undefined}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{t("pics")}</InputAdornment>,
                                }}
                            />
                        </FormControl>

                        <FormControlLabel
                            name='forceLimit'
                            control={<Switch disabled={order?.Album?.claimed} checked={forceLimit} onChange={handleInputChange} />}
                            label={t("force limit(no extra pics)")}
                        />
                        {!forceLimit && <FormControl margin="normal" fullWidth>
                            <FormLabel>{t("append picture price")}</FormLabel>
                            <TextField
                                disabled={order?.Album?.claimed}
                                size="small"
                                placeholder={t("append picture price")}
                                name='pricePerPic'
                                value={pricePerPic}
                                onChange={handleInputChange}
                                error={!!pricePerPicError}
                                helperText={pricePerPicError || undefined}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">元/{t("pics")}</InputAdornment>,
                                }}
                            />
                        </FormControl>}
                        <FormControlLabel
                            name='showImageName'
                            disabled={order?.Album?.claimed}
                            control={<Switch checked={showImageName} onChange={handleInputChange} />}
                            label={t("show image name")}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2,
                            }}
                        >
                            <Button onClick={save} variant="contained" color="primary" type="submit">
                                {t("Save")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
}));

export default withAuth(Step1);
